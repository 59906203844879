// src/pages/IssuedAndImportedBooks.js
import { useNavigate } from "react-router-dom";
import { Container, Typography } from "@mui/material";
import CustomButton from "../../components/CustomButton";
import { useTranslation } from "react-i18next";
import useLanguage from "../../utils/useLanguage";
import nextIcon from "../../assets/next_icon.png";
import previousIcon from "../../assets/previous_icon.png";
import BackToDashboard from "../../components/BackToDashboard";

export default function DashboardPage() {
  const navigate = useNavigate();
  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();

  const viewImportedBooksPage = () => {
    navigate("/importedBooks");
  };

  const viewIssuedBooksPage = () => {
    navigate("/issuedBooks");
  };

  return (
    <div className="e-container">
      <div
        className="view-users-page-container"
        style={{ direction: currentDirection }}
      >
        <div>
          <BackToDashboard
            title={t("back")}
            variant="blank"
            icon={currentDirection === "ltr" ? previousIcon : nextIcon}
            iconSize={14}
          />
        </div>

        <div className="control-panel-container">
          <div className="user-section">
            <div className="user">
              <span className="user-text">{`${t("user")}:`}</span>
              <span className="user-text">{`${localStorage.getItem(
                "username"
              )}`}</span>
            </div>
          </div>
          <h3>{t("booksManagement")}</h3>
          <div className="control-panel-actions">
            <CustomButton
              title={t("issuedBooks")}
              variant="blank"
              onClick={viewIssuedBooksPage}
            />
            <CustomButton
              title={t("importedBooks")}
              variant="blank"
              onClick={viewImportedBooksPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
