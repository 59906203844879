// src/pages/AdminsLogin.js
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import ErrorBox from "../../components/ErrorBox";
import InputLabel from "../../components/InputLabel";

import LanguageSwitcher from "../../components/LanguageSwitcher";
import logo from "../../assets/logo.png";
import Modal from "../../components/Modal"; // Assuming you have a Modal component

const AdminsLogin = ({ isAuthenticatedAdmin, setIsAuthenticatedAdmin }) => {
  const { t } = useTranslation("general");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuperAdminLoginModal, setShowSuperAdminLoginModal] =
    useState(false); // New state for modal visibility
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      setError(true);
      setErrorMessage(t("emptyInputFields"));
      return;
    }

    // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError(true);
      setErrorMessage(t("invalidEmail"));
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/adminlogin`,
        { email, password }
      );

      const { token, user } = response.data;
      localStorage.setItem("userType", "admin");
      console.log("Res: ", response.data);
      localStorage.setItem("token", token);
      localStorage.setItem("userId", user.id);
      localStorage.setItem("email", user.email);
      localStorage.setItem("username", user.username);
      setIsAuthenticatedAdmin(token);
      navigate("/controlPanel");
    } catch (error) {
      console.error("Login error:", error);
      setError(true);
      setErrorMessage(error.response?.data?.error || t("loginFailed"));
    }
  };

  const handleSuperAdminLogin = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      setError(true);
      setErrorMessage(t("emptyInputFields"));
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/superAdminlogin`,
        { email, password }
      );

      localStorage.setItem("userType", "admin");
      const { token, user } = response.data;
      console.log("Res: ", response.data);
      localStorage.setItem("SuperAdminToken", token);
      localStorage.setItem("username", "Super-Admin");
      setIsAuthenticatedAdmin(token);
      navigate("/ControlPanel");
    } catch (error) {
      console.error("Login error:", error);
      setError(true);
      setErrorMessage(error.response?.data?.error || t("loginFailed"));
    }
  };

  const handleCloseModal = () => {
    setShowSuperAdminLoginModal(false);
  };

  return (
    <div className="onboarding-container">
      <div className="onboarding">
        <div className="main-wrapper">
          <div className="language-switcher-container">
            <div className="language-switcher">
              <LanguageSwitcher />
            </div>
          </div>
          <div>
            <img src={logo} alt="Ehsibly" className="website-logo" />
            <p className="intro-text">
              <span className="bold-text">{t("websiteTitle")} </span>
              {t("websiteDescription")}
            </p>
          </div>

          <form className="rigister-login-form" id="login-form">
            <h2>{t("login")}</h2>
            {error && <ErrorBox>{errorMessage}</ErrorBox>}

            <div className="inputContainer">
              <InputLabel title={t("email")} className="label" />
              <CustomInput
                placeholder={t("email")}
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                errorText={error && !email ? t("inputFieldCannotBeEmpty") : ""}
              />
            </div>

            <div className="inputContainer">
              <InputLabel title={t("password")} className="label" />
              <CustomInput
                placeholder={t("password")}
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                errorText={
                  error && !password ? t("inputFieldCannotBeEmpty") : ""
                }
              />
            </div>

            <CustomButton
              title={t("login")}
              onClick={(e) => handleLogin(e)}
              className="onboarding-button"
            />

            <button
              id="forgot-password-button"
              className={"forgetPasswordTextButton"}
              type="button"
              onClick={() => setShowSuperAdminLoginModal(true)} // Open modal
            >
              {t("loginAsSuperAdmin")}
            </button>

            {showSuperAdminLoginModal && (
              <Modal onClose={handleCloseModal}>
                <>
                  <h2>{t("login")}</h2>
                  {error && <ErrorBox>{errorMessage}</ErrorBox>}

                  <div className="inputContainer">
                    <InputLabel title={t("email")} className="label" />
                    <CustomInput
                      placeholder={t("email")}
                      value={email}
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      errorText={
                        error && !email ? t("inputFieldCannotBeEmpty") : ""
                      }
                    />
                  </div>

                  <div className="inputContainer">
                    <InputLabel title={t("password")} className="label" />
                    <CustomInput
                      placeholder={t("password")}
                      value={password}
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      errorText={
                        error && !password ? t("inputFieldCannotBeEmpty") : ""
                      }
                    />
                  </div>

                  <CustomButton
                    title={t("login")}
                    onClick={(e) => handleSuperAdminLogin(e)}
                    className="onboarding-button"
                  />
                </>
              </Modal>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminsLogin;
