import React, { useEffect } from "react";
import classes from "./Modal.module.css";
import closeIcon from "../assets/close_icon.png";
import useLanguage from "../utils/useLanguage";
import { useTranslation } from "react-i18next";

export default function Modal({
  open,
  onClose,
  title,
  children,
  error,
  footer,
}) {
  const { currentDirection } = useLanguage();
  const { t } = useTranslation("general");

  useEffect(() => {
    // Add the 'no-scroll' class to the body when the modal is open
    document.body.classList.add("no-scroll");
    // console.log("Added 'no-scroll' class to body", document.body.classList);

    // Remove the 'no-scroll' class from the body when the modal is closed
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  return (
    <div className={classes.modalOverlay} onClick={onClose}>
      <div
        className={classes.modalContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.modal}>
          <section className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>{t(title)}</h2>
            <button
              id="modal-close-button"
              className={classes.modalCloseIcon}
              onClick={onClose}
            >
              <img
                src={closeIcon}
                alt="close modal"
                className={classes.closeIcon}
                style={
                  currentDirection === "rtl"
                    ? { left: "20px" }
                    : { right: "20px" }
                }
              />
            </button>
          </section>
          <section className={classes.modalBody}>{children}</section>
          {error && <section className={classes.error}>{error}</section>}
          <section className={classes.modalFooter}>{footer}</section>
        </div>
      </div>
    </div>
  );
}
