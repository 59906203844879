import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Card from "../../components/Card";
import { Container } from "@mui/system";
import ConfirmationBox from "../../components/ConfirmationBox";
import ErrorBox from "../../components/ErrorBox";
import Loader from "../../components/Loader";

export default function VerfiyEmailVerfiyEmail() {
  const { t } = useTranslation("general");
  const location = useLocation();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const id = queryParams.get("id");
    const type = queryParams.get("type");

    const sendVerfiyToken = async () => {
      try {
        setLoading(true);
        const res = await axios.get(
          `${process.env.REACT_APP_URL}/verify-email?token=${token}&id=${id}&type=${type}`
        );

        if (res) {
          setSuccess(res?.data?.message);
          console.log("res?.data?.message", res?.data?.message);
        }
        setLoading(false);
      } catch (err) {
        if (err.response && err.response.status === 400) {
          console.log("err.response.status");
          setError(err.response.data);
          console.log("err.response.data", err.response.data);
        } else {
          setError("networkError");
        }
        setLoading(false);
      }
    };

    sendVerfiyToken();
  }, [location]);

  //   return <>{!error ? <h2>{t(success)}</h2> : <h2>{t(error)}</h2>}</>;

  if (loading) {
    return <Loader fullHeight={true} />;
  }

  return (
    <Container maxWidth="xs">
      <Card
        Element="div"
        cardTitle={success ? t("done") : t("failed")}
        collapsable={false}
      >
        {success ? (
          <>
            <ConfirmationBox>{t(success)}</ConfirmationBox>
            {/* <GradientButton element={Link} to="/login">
              {t("login")}
            </GradientButton> */}
          </>
        ) : (
          <ErrorBox>{t(error)}</ErrorBox>
        )}
      </Card>
    </Container>
  );
}
