import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Typography } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";

import Modal from "../../components/Modal";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import Loader from "../../components/Loader";
import Dropdown from "../../components/Dropdown";

import useLanguage from "../../utils/useLanguage";
import { UserAuth } from "../../utils/AuthContext";
import { DataContext } from "../../utils/DataContext";

import addIcon from "../../assets/add_icon.png";
import nextIcon from "../../assets/next_icon.png";
import previousIcon from "../../assets/previous_icon.png";
import editIcon from "../../assets/edit_icon.png";
import closeIcon from "../../assets/close_icon.png";
import saveIcon from "../../assets/save_icon.png";
import classes from "../../components/Modal.module.css";
import BackToDashboard from "../../components/BackToDashboard";

export default function SuppliersPage() {
  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();
  // const [suppliers, setSuppliers] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  // const [networkError, setNetworkError] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [updateSupplierModal, setUpdateSupplierModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [updateSupplierFields, setUpdateSupplierFields] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Added pagination state
  const [totalPages, setTotalPages] = useState(1); // Added totalPages state
  const [totalRecords, setTotalRecords] = useState(0); // Added totalRecords state
  const [loadingModal, setLoadingModal] = useState(false);

  const { userData } = useContext(UserAuth);

  const {
    handleShowSupplierModal,
    error,
    currencies,
    supplierCurrency,
    setSupplierCurrency,
    supplierModal,
    setSupplierModal,
    handleSaveSupplier,
    supplierFields,
    setSupplierFields,
    suppliers,
    setSuppliers,
    pageLoading,
    setPageLoading,
  } = useContext(DataContext);

  useEffect(() => {
    getSuppliers();
  }, [currentPage, searchTerm, userData]);

  useEffect(() => {
    setFilteredSuppliers(
      suppliers.filter((supplier) =>
        supplier.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, suppliers]);

  const getSuppliers = async () => {
    try {
      setPageLoading((prev) => ({
        ...prev,
        isPending: true,
      }));
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/getSuppliers`,
        {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
            "User-Id": parseInt(userData?.userId),
            "organization-Id": parseInt(userData?.organizationId),
          },
          params: {
            page: currentPage,
            limit: 5,
            search: searchTerm,
          },
        }
      );

      if (response?.status === 200) {
        setPageLoading((prev) => ({
          ...prev,
          isPending: false,
          isSuccess: true,
        }));
      } else {
        setPageLoading((prev) => ({
          ...prev,
          isPending: false,
          isFailed: true,
        }));
      }

      if (response.data && response.data.suppliers) {
        setSuppliers(response.data.suppliers);
        setFilteredSuppliers(response.data.suppliers);
        setTotalPages(response.data.totalPages); // Set total pages from the response
        setTotalRecords(response.data.totalRecords); // Set total records from the response
      } else {
        console.error("Failed to get the suppliers");
      }
    } catch (error) {
      // setNetworkError(true);
      console.error("Failed to get the suppliers", error);
      setPageLoading((prev) => ({
        ...prev,
        isPending: false,
        isFailed: true,
      }));
    }
  };

  const openUpdateSupplierModal = (supplier) => {
    setSelectedSupplier(supplier);
    setUpdateSupplierFields({
      supplierName: supplier.name,
      supplierCompany: supplier.company,
      supplierLocation: supplier.location,
      supplierCurrency: supplier.currency,
    }); // Initialize fields for editing
    setIsEditing(false); // Set to editing mode
    setUpdateSupplierModal(true);
  };

  const handleInputChange = (e) => {
    // console.log("setSupplierFields", e.target.name);
    setSupplierFields({
      ...supplierFields,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateInputChange = (e, fieldName) => {
    setUpdateSupplierFields({
      ...updateSupplierFields,
      [fieldName]: e.target.value,
    });
  };

  const handleUpdateSupplier = async () => {
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);

    try {
      setLoadingModal(true);
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/updateSupplier/${selectedSupplier.id}`,
        updateSupplierFields,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
          },
        }
      );

      if (response.data.message === "Supplier updated successfully") {
        await getSuppliers(); // Refetch suppliers to get updated data
        setUpdateSupplierModal(false);
        // console.log("Supplier updated successfully");
      } else {
        console.error("Failed to update supplier:", response.data.message);
      }
      setLoadingModal(false);
    } catch (error) {
      console.error("Error updating supplier:", error);
      setLoadingModal(false);
    }
  };

  const handleAddSupplier = async () => {
    setLoadingModal(true);
    await handleSaveSupplier();
    setLoadingModal(false);
  };

  const handleCancel = () => {
    setSupplierModal(false);
    setUpdateSupplierModal(false);
    setSelectedSupplier(null);
  };

  // if (pageLoading?.isPending) {
  //   return <Loader fullHeight />;
  // }

  return (
    <div className="e-container">
      <div
        className="view-users-page-container"
        style={{ direction: currentDirection }}
      >
        <div>
          <BackToDashboard
            title={t("back")}
            variant="blank-fit-content"
            icon={currentDirection === "ltr" ? previousIcon : nextIcon}
            iconSize={14}
          />
        </div>
        <div className="users-title-section">
          <h4 className="title">
            {t("suppliers", { defaultValue: "Suppliers" })}
          </h4>
          {userData?.userId ? (
            <CustomButton
              title={t("addSupplier")}
              variant="blank"
              icon={addIcon}
              iconSize={20}
              onClick={handleShowSupplierModal}
            />
          ) : null}
        </div>

        <CustomInput
          name="supplierName"
          value={searchTerm}
          placeholder={searchTerm ? "" : t("search")}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {pageLoading?.isPending ? (
          <Loader />
        ) : filteredSuppliers.length === 0 ? (
          <p>{t("noSuppliers")}.</p>
        ) : (
          <ul className="users-list" style={{ direction: currentDirection }}>
            {filteredSuppliers.map((supplier) => (
              <li
                className="user-list-item"
                key={supplier.id}
                onClick={() => openUpdateSupplierModal(supplier)}
                style={{ cursor: "pointer" }}
              >
                <p className="user-username">{supplier.name}</p>
                {supplier.company && (
                  <Typography variant="subtitle1" color="textSecondary">
                    {supplier.company}
                  </Typography>
                )}
              </li>
            ))}
          </ul>
        )}

        <section className="pagination">
          <div className="pagination-buttons">
            <CustomButton
              variant="blank"
              title={t("previous")}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              icon={currentDirection === "ltr" ? previousIcon : nextIcon}
              iconSize={15}
            />
            <span className="pagination-location">
              {t("page")}: {currentPage} - {totalPages}
            </span>
            <CustomButton
              title={t("next")}
              variant="blank"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              icon={currentDirection === "ltr" ? nextIcon : previousIcon}
              iconSize={15}
              reverseIcon
            />
          </div>

          <div className="pagination-buttons">
            <span className="pagination-location">
              {t("totalNumber")}: {totalRecords}
            </span>
          </div>
        </section>

        {updateSupplierModal && (
          <Modal
            title={isEditing ? t("editSupplier") : t("supplier")}
            onClose={handleCancel}
            footer={
              <>
                {loadingModal ? <Loader /> : null}
                <section className="edit-user-actions">
                  {isEditing ? (
                    <>
                      <CustomButton
                        onClick={handleUpdateSupplier}
                        title={t("confirm")}
                        icon={saveIcon}
                        iconSize={16}
                        disabled={loadingModal}
                      />
                      <CustomButton
                        title={t("cancel")}
                        onClick={handleCancel}
                        variant="blank"
                        icon={closeIcon}
                        iconSize={14}
                      />
                    </>
                  ) : (
                    <>
                      <CustomButton
                        variant="blank"
                        title={t("edit")}
                        onClick={() => setIsEditing(true)}
                        icon={editIcon}
                        iconSize={18}
                      />
                    </>
                  )}
                </section>
              </>
            }
          >
            <div>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("supplierName")}: </span>
                      <CustomInput
                        name="supplierName"
                        value={updateSupplierFields.supplierName || ""}
                        onChange={(e) =>
                          handleUpdateInputChange(e, "supplierName")
                        }
                      />
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p variant="body1">{t("supplierName")}:</p>
                      <p variant="body1">{selectedSupplier?.name}</p>
                    </div>
                  )}
                </div>
              </section>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("supplierCompany")}: </span>
                      <CustomInput
                        name="supplierCompany"
                        value={updateSupplierFields.supplierCompany || ""}
                        onChange={(e) =>
                          handleUpdateInputChange(e, "supplierCompany")
                        }
                      />
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p variant="body1">{t("supplierCompany")}:</p>
                      <p variant="body1">{selectedSupplier?.company}</p>
                    </div>
                  )}
                </div>
              </section>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("supplierLocation")}: </span>
                      <CustomInput
                        name="supplierLocation"
                        value={updateSupplierFields.supplierLocation || ""}
                        onChange={(e) =>
                          handleUpdateInputChange(e, "supplierLocation")
                        }
                      />
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p variant="body1">{t("supplierLocation")}:</p>
                      <p variant="body1">{selectedSupplier?.location}</p>
                    </div>
                  )}
                </div>
              </section>
              <section>
                <div>
                  <div className="view-user-entity">
                    <p variant="body1">{t("supplierCurrency")}:</p>
                    <p variant="body1">{selectedSupplier?.currency}</p>
                  </div>
                </div>
              </section>
            </div>
          </Modal>
        )}

        {supplierModal && (
          <Modal
            title="addSupplier"
            onClose={() => handleCancel("supplier")}
            footer={
              <>
                {loadingModal ? <Loader /> : null}
                <section className={classes.actions}>
                  <CustomButton
                    onClick={handleAddSupplier}
                    title={t("confirm")}
                    icon={saveIcon}
                    iconSize={16}
                    disabled={loadingModal}
                  />
                  <CustomButton
                    title={t("cancel")}
                    onClick={() => handleCancel("supplier")}
                    variant="blank"
                  />
                </section>
              </>
            }
          >
            <div className={classes.flexableForm}>
              <section className={classes.section}>
                <div className={classes.inputContainer}>
                  <span
                    className={
                      currentDirection === "rtl" ? classes.shortSpan : ""
                    }
                  >
                    {t("supplierName")}:{" "}
                  </span>
                  <CustomInput
                    name="supplierName"
                    value={supplierFields?.supplierName}
                    onChange={(e) => handleInputChange(e, "supplier")}
                    flex={true}
                    errorText={
                      error === "noSupplierName" ? t("noSupplierName") : null
                    }
                  />
                </div>
              </section>
              <section className={classes.section}>
                <div className={classes.inputContainer}>
                  <span
                    className={
                      currentDirection === "rtl" ? classes.shortSpan : ""
                    }
                  >
                    {t("supplierCompany")}:{" "}
                  </span>
                  <CustomInput
                    name="supplierCompany"
                    value={supplierFields?.supplierCompany}
                    onChange={(e) => handleInputChange(e, "supplier")}
                    flex={true}
                  />
                </div>
              </section>
              <section className={classes.section}>
                <div className={classes.inputContainer}>
                  <span
                    className={
                      currentDirection === "rtl" ? classes.shortSpan : ""
                    }
                  >
                    {t("supplierLocation")}:{" "}
                  </span>
                  <CustomInput
                    name="supplierLocation"
                    value={supplierFields?.supplierLocation}
                    onChange={(e) => handleInputChange(e, "supplier")}
                    flex={true}
                  />
                </div>
              </section>
              <section className={classes.section}>
                <div className={classes.inputContainer}>
                  <span
                    className={
                      currentDirection === "rtl" ? classes.shortSpan : ""
                    }
                  >
                    {t("supplierCurrency")}:{" "}
                  </span>
                  <Dropdown
                    options={currencies}
                    selected={supplierCurrency}
                    setSelected={setSupplierCurrency}
                    thick
                    flex
                  />
                </div>
              </section>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}
