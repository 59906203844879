import React, { useState } from "react";
import * as pdfjsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import axios from "axios";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker; // Set the worker source

const PdfAnalyzer = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [result, setResult] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPdfFile(file);
      setResult(null);
    }
  };

  const testWebsite = async (url) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/test-url?url=${url}`
      );
    } catch (err) {
      console.error("Failed to check the website");
    }
  };

  const analyzePdf = () => {
    if (!pdfFile) {
      return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      const typedArray = new Uint8Array(reader.result);
      const loadingTask = pdfjsLib.getDocument(typedArray);

      loadingTask.promise
        .then((pdf) => {
          let pageCount = pdf.numPages;
          let textContent = "";

          const pagePromises = [];
          for (let i = 1; i <= pageCount; i++) {
            pagePromises.push(
              pdf.getPage(i).then((page) => {
                return page.getTextContent().then((text) => {
                  textContent +=
                    text.items.map((item) => item.str).join(" ") + " ";
                });
              })
            );
          }

          Promise.all(pagePromises).then(async () => {
            const word = "company";
            const regex = new RegExp(`\\b${word}\\b`, "gi");
            const matches = textContent.match(regex);
            const count = matches ? matches.length : 0;

            // Find websites using regex
            const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/gi;
            const urls = textContent.match(urlRegex) || [
              "https://www.google.iq",
            ];
            const urlStatuses = await Promise.all(
              urls.map(async (url) => ({
                url,
                status: await testWebsite(url),
              }))
            );

            setResult(
              <>
                <p>
                  {count > 0
                    ? `The word "company" was found ${count} time(s).`
                    : 'The word "company" was not found.'}
                </p>
                {urls.length > 0 ? (
                  <div>
                    <h3>Website Analysis:</h3>
                    <ul>
                      {urlStatuses.map(({ url, status }, index) => (
                        <li key={index}>
                          {url} - {status}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p>No websites found in the PDF.</p>
                )}
              </>
            );
          });
        })
        .catch((error) => {
          console.error("Error processing PDF: ", error);
          setResult("Error processing PDF.");
        });
    };

    reader.readAsArrayBuffer(pdfFile);
  };

  return (
    <div className="e-container">
      <div
        className="view-users-page-container"
        // style={{ direction: currentDirection }}
      >
        <h1>PDF Analyzer</h1>
        <input
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
        />
        <button onClick={analyzePdf} disabled={!pdfFile}>
          Analyze PDF
        </button>
        {result && <div>{result}</div>}
      </div>
    </div>
  );
};

export default PdfAnalyzer;
