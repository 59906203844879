// src/pages/organization-pages/OrgShippedContainers.js
import { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import "../InvoicesManagement.scss";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CustomCheckbox from "../../components/CustomCheckbox";
import ChartModal from "../../components/ChartModal";
import CustomInput from "../../components/CustomInput";
import Dropdown from "../../components/Dropdown";
import { toMoney } from "../../utils/toMoney";
import { useTranslation } from "react-i18next";
import useLanguage from "../../utils/useLanguage";
import CustomButton from "../../components/CustomButton";
import containersIcon from "../../assets/container.png";
import CustomLink from "../../components/CustomLink";
import { getCleanedInvoiceNumber } from "../../utils/getCleanedInvoiceNumber";
import InformationBox from "../../components/InformationBox";
import Modal from "../../components/Modal";
import classes from "../../components/Modal.module.css";

import supplierIcon from "../../assets/supplier_icon.png";
import previousIcon from "../../assets/previous_icon.png";
import nextIcon from "../../assets/next_icon.png";
import addIconLight from "../../assets/add_icon_light.png";
import searchIcon from "../../assets/search_icon.png";
import saveIcon from "../../assets/save_icon.png";
import closeIcon from "../../assets/close_icon.png";
import printIcon from "../../assets/printer_icon.png";

import UserSection from "../../components/UserSection";
import { DataContext } from "../../utils/DataContext";
import PDFUploader from "../../components/PDFUploader";
import { sanitizeNumber } from "../../utils/sanitizeNumber";
import { UserAuth } from "../../utils/AuthContext";
import NetworkError from "../../components/NetworkError";
import BackToDashboard from "../../components/BackToDashboard";
import Loader from "../../components/Loader";
import { formatDate, toDate } from "../../utils/toDate";
import addCommas from "../../utils/addCommas";

const OrgShippedContainers = () => {
  const { t } = useTranslation("general");

  const rows = [
    {
      title: "Container number",
      value: "containerNumber",
    },
    {
      title: "Created by",
      value: "createdBy",
    },
    {
      title: "Expected arrival date",
      value: "expectedArrivalDate",
    },
    {
      title: "Final destination",
      value: "finalDestination",
    },
    {
      title: "Customer",
      value: "customer",
    },
    {
      title: "Shipper",
      value: "shipper",
    },
    {
      title: "Material",
      value: "material",
    },
    {
      title: "Number of boxes",
      value: "numberOfBoxes",
    },
    {
      title: "Exit date",
      value: "exitDate",
    },
    {
      title: "Supplier",
      value: "supplier",
    },
    {
      title: "Date of entry",
      value: "dateOfEntry",
    },
    {
      title: "Shipping date",
      value: "shippingDate",
    },
    {
      title: "Permits receiving date",
      value: "permitsReceivingDate",
    },
    {
      title: "Sub company",
      value: "subCompany",
    },
    {
      title: "Amount for supplier",
      value: "amountForSupplier",
    },
    {
      title: "Abstract amount for customer",
      value: "abstractAmountForCustomer",
    },
    {
      title: "Final amount for customer",
      value: "finalAmountForCustomer",
    },

    {
      title: "Currency",
      value: "currency",
    },
    {
      title: "Currency",
      value: "currency",
    },

    {
      title: "Notes",
      value: "notes",
    },
  ];

  const [newRow, setNewRow] = useState({
    containerNumber: "",
    createdBy: "",
    expectedArrivalDate: new Date(),
    finalDestination: "",
    shipper: "",
    material: "",
    numberOfBoxes: 0,
    exitDate: new Date(),
    dateOfEntry: new Date(),
    shippingDate: new Date(),
    permitsReceivingDate: new Date(),
    amountForSupplier: "",
    abstractAmountForCustomer: "",
    finalAmountForCustomer: "",
    notes: "",
  });

  const [selectedTitle, setSelectedTitle] = useState({
    title: t("Container number"),
    value: "containerNumber",
  });
  const [searchQuery, setSearchQuery] = useState("");

  const { currentDirection } = useLanguage();

  const [selectedCurrency, setSelectedCurrency] = useState({
    title: "USD",
    id: "USD",
  });

  const [showInputFields, setShowInputFields] = useState(false);
  const [showAddInvoiceButton, setShowAddInvoiceButton] = useState(true);
  const [newRecord, setNewRecord] = useState({
    containerNumber: "",
    createdBy: "",
    expectedArrivalDate: new Date(),
    finalDestination: "",
    shipper: "",
    material: "",
    numberOfBoxes: 0,
    exitDate: new Date(),
    dateOfEntry: new Date(),
    shippingDate: new Date(),
    permitsReceivingDate: new Date(),
    amountForSupplier: "",
    abstractAmountForCustomer: "",
    finalAmountForCustomer: "",
    notes: "",
  });
  const [showAddRowButton, setShowAddRowButton] = useState(true);
  // const [invoiceDocs, setInvoiceDocs] = useState([]);
  const pdfUploaderRef = useRef(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedShippedContainersStatus, setSelectedShippedContainersStatus] =
    useState({
      title: "Active",
      value: "active",
    });

  const { userData } = useContext(UserAuth);

  const {
    suppliers,
    getSuppliers,
    supplierModal,
    setSupplierModal,
    selectedSupplier,
    error,
    setError,
    loading,
    pageLoading,
    setPageLoading,
    shippedContainers,
    setShippedContainers,
    getCustomers,
    selectedCustomer,
    setSelectedCustomer,
    customers,
    currentBalance,
    setCurrentBalance,
    handleSelectSupplier,
    handleSelectCustomer,
    handleSaveSupplier,
    supplierFields,
    setSupplierFields,
    supplierCurrency,
    setSupplierCurrency,
    fetchShippedContainers,
    fetchArchivedShippedContainers,
    fetchShippedContainersBalance,
    selectedDate,
    setSelectedDate,
    currencies,
    networkError,
    setNetworkError,
    searchError,
    searchErrorMessage,
    handleShowSupplierModal,
    shippedContainersCurrentPage,
    shippedContainersCount,
    shippedContainersTotalPages,
    setShippedContainersCurrentPage,
    setShippedContainersCount,
    subCompanies,
    setSubCompanies,
    selectedSubCompany,
    setSelectedSubCompany,
    getSubCompanies,
    handleSelectSubCompany,
  } = useContext(DataContext);

  console.log("Pending", pageLoading);

  // const handleChildUploadDocs = (invoiceId) => {
  //   if (pdfUploaderRef.current) {
  //     pdfUploaderRef.current.handleUploadDocs(invoiceId);
  //   }
  // };

  const handleSelectEmployee = (employee) => {
    setSelectedEmployee(employee);
  };

  const getEmployees = async () => {
    const token =
      localStorage.getItem("SuperAdminToken") ||
      localStorage.getItem("adminToken");

    const organizationId = userData?.organizationId;

    try {
      setPageLoading((prev) => ({
        ...prev,
        isPending: true,
      }));

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/getEmployees`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": organizationId,
          },
        }
      );
      if (response?.status === 200) {
        setPageLoading((prev) => ({
          ...prev,
          isPending: false,
          isSuccess: true,
        }));
      } else {
        setPageLoading((prev) => ({
          ...prev,
          isPending: false,
          isFailed: true,
        }));
      }

      if (response.data && response.data.employees) {
        const transformedResults = response.data.employees.map((emp) => {
          return {
            ...emp,
            name: emp?.username ? emp?.username : null,
          };
        });

        setEmployees(transformedResults);
      } else {
        console.error("Failed to get the users");
      }
    } catch (error) {
      setPageLoading((prev) => ({
        ...prev,
        isPending: false,
        isFailed: true,
      }));
      setNetworkError(true);
      console.error("Failed to get the users", error);
    }
  };

  // useEffect(() => {
  //   if (!suppliers.length) {
  //     getSuppliers();
  //   }
  //   if (!customers.length) {
  //     getCustomers(0, 0);
  //   }
  //   if (!subCompanies.length) {
  //     getSubCompanies(0, 0);
  //   }
  //   if (!employees.length) {
  //     getEmployees();
  //   }
  // }, []);

  useEffect(() => {
    fetchShippedContainers();

    if (userData?.userType !== "organization") {
      setSelectedEmployee({ name: userData?.username, id: userData?.userId });
    }
  }, [shippedContainersCurrentPage]);

  useEffect(() => {
    if (selectedSupplier?.id && selectedCustomer?.id) {
      // fetchShippedContainersBalance();
    }
  }, [selectedSupplier, selectedCustomer, shippedContainersCount]);

  const handleAddSupplier = async () => {
    setLoadingModal(true);
    await handleSaveSupplier();
    setLoadingModal(false);
  };

  const [selectAll, setSelectAll] = useState(false);
  const [chartModalOpen, setChartModalOpen] = useState(false);
  const [tempCurrentBalance, setTempCurrentBalance] = useState({
    value: 0,
    containerId: 0,
  });

  const [dublicatedContainerNumber, setDublicatedContainerNumber] =
    useState("");

  const [searchBy, setSearchBy] = useState("");

  const balanceStyle =
    currentBalance?.value >= 0 ? "positive-value" : "negative-value";

  const handleShowChart = () => {
    setChartModalOpen(true);
  };

  const handleCloseChart = () => {
    setChartModalOpen(false);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    // Update the selected status for all rows in the data array
    // setData((prevData) => {
    //   return prevData.map((row) => {
    //     return { ...row, selected: !selectAll };
    //   });
    // });
  };

  // Prevent entering numbers in input field
  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
      "Enter",
    ];
    const isNumberOrSymbol = /\d|\,|\./.test(e.key);

    if (!isNumberOrSymbol && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleInputChange = (e, modal) => {
    const { name, value } = e.target;

    console.log(name, value);
    setError("");

    if (modal === "supplier") {
      setSupplierFields((prev) => ({ ...prev, [name]: value }));
    } else {
      setNewRecord((prevRec) => {
        let updatedRecord = { ...prevRec, [name]: value };

        if (name === "amountInSupCur") {
          if (value) {
            setTempCurrentBalance((prev) => ({
              ...prev,
              value: currentBalance.value + parseFloat(sanitizeNumber(value)),
            }));
          } else {
            setTempCurrentBalance((prev) => ({
              ...prev,
              value: currentBalance.value,
            }));
          }
        }

        return updatedRecord;
      });

      // ! Old code
      setNewRow((prevRow) => {
        let updatedRow = { ...prevRow, [name]: value };

        if (name === "amountInSupCur") {
          if (value) {
            setTempCurrentBalance((prev) => ({
              ...prev,
              value: currentBalance.value + parseFloat(value),
            }));
          } else {
            setTempCurrentBalance((prev) => ({
              ...prev,
              value: currentBalance.value,
            }));
          }
        }

        return updatedRow;
      });
    }
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    const name = e.target?.name;

    setNewRow((prevRow) => ({ ...prevRow, [name]: new Date(date) }));
    setNewRecord((prevRecord) => ({ ...prevRecord, [name]: new Date(date) }));
  };

  const handleSearch = async () =>
    await fetchShippedContainers(
      selectedSupplier?.id,
      selectedCustomer?.id,
      selectedTitle.value,
      searchQuery ||
        selectedDate ||
        selectedSubCompany?.id ||
        selectedEmployee?.id
    );

  const handleAddRow = async () => {
    try {
      const token = userData?.token;
      const userId = parseInt(userData.userId, 10);

      if (!userId) {
        console.error("User ID not found in localStorage");
        return;
      }

      setLoadingModal(true);
      const newShippedContainer = await axios.post(
        `${process.env.REACT_APP_URL}/createShippedContainer`,
        {
          ...newRow,
          supplierId: selectedSupplier?.id,
          customerId: selectedCustomer?.id,
          subCompanyId: selectedSubCompany?.id,
          subCompanyName: selectedSubCompany?.name,
          createdBy: userData?.userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
          },
        }
      );

      if (newShippedContainer.status === 200) {
        console.log("DATA", {
          ...newShippedContainer,
        });
        setShippedContainers((prev) => [newShippedContainer?.data, ...prev]);
        // [newInvoice.data, ...prev]
      }
      // ! IMPORANTT
      // fetchData(selectedSupplier?.id);
      const newInvoiceId = newShippedContainer?.data?.id;
      // const uploadDocuments = handleChildUploadDocs(newInvoiceId);

      setNewRecord({
        containerNumber: "",
        createdBy: "",
        expectedArrivalDate: new Date(),
        finalDestination: "",
        shipper: "",
        material: "",
        numberOfBoxes: 0,
        exitDate: new Date(),
        dateOfEntry: new Date(),
        shippingDate: new Date(),
        permitsReceivingDate: new Date(),
        amountForSupplier: "",
        abstractAmountForCustomer: "",
        finalAmountForCustomer: "",
        notes: "",
      });
      setNewRow({
        containerNumber: "",
        createdBy: "",
        expectedArrivalDate: new Date(),
        finalDestination: "",
        shipper: "",
        material: "",
        numberOfBoxes: 0,
        exitDate: new Date(),
        dateOfEntry: new Date(),
        shippingDate: new Date(),
        permitsReceivingDate: new Date(),
        amountForSupplier: "",
        abstractAmountForCustomer: "",
        finalAmountForCustomer: "",
        notes: "",
      });
      setError("");
      setShowInputFields(false);
      // setCurrentBalance(tempCurrentBalance);
      // setData((prev) => [newInvoice.data, ...prev]);
      setShippedContainersCount((prev) => prev + 1);
    } catch (error) {
      console.error("Error adding new shippedContainer:", error);
      setError("addInvoiceFailed");
      setNetworkError(true);
    } finally {
      setLoadingModal(false);
    }
  };

  const handleShowInputFields = () => {
    setShowInputFields(true);
  };

  const handleCancel = (modal) => {
    if (modal === "supplier") {
      setSupplierModal(false);
    } else {
      setShowAddRowButton(true);
      setShowInputFields(false);
      setError("");
      // setInvoiceDocs([]);
      setDublicatedContainerNumber("");
      setNewRecord({
        containerNumber: "",
        createdBy: "",
        expectedArrivalDate: new Date(),
        finalDestination: "",
        shipper: "",
        material: "",
        numberOfBoxes: 0,
        exitDate: new Date(),
        dateOfEntry: new Date(),
        shippingDate: new Date(),
        permitsReceivingDate: new Date(),
        amountForSupplier: "",
        abstractAmountForCustomer: "",
        finalAmountForCustomer: "",
        notes: "",
      });
      setNewRow({
        containerNumber: "",
        createdBy: "",
        expectedArrivalDate: new Date(),
        finalDestination: "",
        shipper: "",
        material: "",
        numberOfBoxes: 0,
        exitDate: new Date(),
        dateOfEntry: new Date(),
        shippingDate: new Date(),
        permitsReceivingDate: new Date(),
        amountForSupplier: "",
        abstractAmountForCustomer: "",
        finalAmountForCustomer: "",
        notes: "",
      });
    }
  };

  const handleSearchType = async (type) => {
    console.log("type", type);
    setSelectedTitle(type);
    setSelectedDate("");
    // setSelectedSubCompany(null);
    // setSelectedEmployee(null);

    setSearchQuery("");
    setSearchBy("");
    if (
      type.value === "expectedArrivalDate" ||
      type.value === "exitDate" ||
      type.value === "dateOfEntry" ||
      type.value === "shippingDate" ||
      type.value === "permitsReceivingDate"
    ) {
      setSearchBy("date");
    }

    if (type.value === "subCompany") {
      setSearchBy("subCompany");
      await getSubCompanies();
    }

    if (type.value === "createdBy") {
      setSearchBy("createdBy");
    }
  };

  const handlePrint = () => {
    // const selectedRows = data.filter((row) => row.selected);
    const selectedRows = [];
    console.log("Selected Row: ", selectedRows);
    if (selectedRows.length) {
      setError("");
      sessionStorage.setItem("selectedRows", JSON.stringify(selectedRows));
      window.open("/print", "_blank");
    } else {
      setError("noInvoiceToPrint");
    }
  };

  const handleChangeStatus = (status) => {
    const { value } = status;
    setSelectedShippedContainersStatus(status);
    setShippedContainersCurrentPage(1);
    setSearchQuery("");

    if (value === "active") {
      setShowAddInvoiceButton(true);
      fetchShippedContainers();
    } else if (value === "archived") {
      setShowAddInvoiceButton(false);
      fetchArchivedShippedContainers(selectedSupplier?.id);
    }
  };

  // Use effect to reset status to "Active" on component mount

  // if (networkError) {
  //   return (
  //     <main className="main">
  //       <div className="invoice-table-container">
  //         <NetworkError>{t("networkError")}</NetworkError>
  //       </div>
  //     </main>
  //   );
  // }

  return (
    <>
      <main className="e-container">
        <div>
          <BackToDashboard
            title={t("back")}
            variant="blank"
            icon={currentDirection === "ltr" ? previousIcon : nextIcon}
            iconSize={14}
          />
        </div>
        <div className="invoice-table-container">
          {/* <Header /> */}

          <UserSection
            handleShowChart={handleShowChart}
            page="shippedContainers"
          />

          <div className="search-bar customer-bar">
            <section className="col">
              <label>{t("customer")}: </label>
              {selectedCustomer?.id ? (
                <Dropdown
                  options2={[{ id: "All", name: "All" }, ...customers]}
                  selected={selectedCustomer}
                  setSelected={handleSelectCustomer}
                  errorText="Failed to get"
                  // thick
                  getData={getCustomers}
                />
              ) : (
                <span>{t("noCustomersFound")}</span>
              )}
            </section>
          </div>

          {selectedSupplier?.id ? (
            <>
              <div className="user-section">
                <div className="page-title">{t("shippedContainers")}</div>
              </div>
              <div className="search-bar">
                <section className="col">
                  <label>{t("search")}: </label>

                  {searchBy === "date" ? (
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => {
                        setSelectedDate(date.toLocaleDateString("en-CA"));
                      }}
                      dateFormat="yyyy-MM-dd"
                      placeholderText={t("searchByDate")}
                    />
                  ) : searchBy === "subCompany" ? (
                    <>
                      {subCompanies.length ? (
                        <Dropdown
                          options2={subCompanies}
                          selected={selectedSubCompany}
                          setSelected={handleSelectSubCompany}
                          thick
                        />
                      ) : (
                        <span className="error-text">
                          {t("noSubCompaniesFound")}
                        </span>
                      )}
                    </>
                  ) : searchBy === "createdBy" ? (
                    <>
                      {selectedEmployee?.id ? (
                        <Dropdown
                          options2={employees}
                          selected={selectedEmployee}
                          setSelected={handleSelectEmployee}
                          getData={getEmployees}
                          thick
                        />
                      ) : (
                        <span className="error-text">{t("noUsers")}</span>
                      )}
                    </>
                  ) : (
                    <CustomInput
                      placeholder={t("search")}
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      thick
                    />
                  )}
                </section>

                <section className="col title-col">
                  <label>{t("searchTitle")}: </label>
                  <Dropdown
                    options={rows?.filter(
                      (row) =>
                        row.title !== "Customer" && row.title !== "Supplier"
                    )}
                    selected={selectedTitle}
                    setSelected={handleSearchType}
                    thick
                  />
                </section>
                <section className="col button-col">
                  <CustomButton
                    title={t("search")}
                    onClick={handleSearch}
                    icon={searchIcon}
                    iconSize={15}
                  />
                </section>
              </div>

              <div className="top-action-buttons responsive-box">
                {showAddRowButton ? (
                  <>
                    <div className="button-group">
                      {showAddInvoiceButton ? (
                        <CustomButton
                          title={t("addShippedContainer")}
                          onClick={handleShowInputFields}
                          icon={addIconLight}
                          iconSize={20}
                        />
                      ) : null}
                      {/* {data.length !== 0 && !showInputFields ? (
                        <CustomButton
                          title={t("print")}
                          onClick={handlePrint}
                          variant="gray"
                          icon={printIcon}
                          iconSize={18}
                        />
                      ) : null} */}
                    </div>
                    <section className="col title-col ">
                      <label>{t("invoicesStatus")}: </label>
                      <Dropdown
                        options={[
                          { title: "Active", value: "active" },
                          { title: "Archived", value: "archived" },
                        ]}
                        selected={selectedShippedContainersStatus}
                        setSelected={handleChangeStatus}
                        thick
                      />
                    </section>
                  </>
                ) : null}
              </div>

              {pageLoading?.isPending ? (
                <Loader />
              ) : (
                <>
                  {error === "noInvoiceToPrint" ? (
                    <div className="error-message">{t("noInvoiceToPrint")}</div>
                  ) : null}
                  {shippedContainers?.length === 0 && !showInputFields ? (
                    <InformationBox>
                      {!selectedSupplier?.id
                        ? t("noSupplier")
                        : t("noInvoices")}
                    </InformationBox>
                  ) : searchError ? (
                    <>
                      <InformationBox>{t(searchErrorMessage)}</InformationBox>
                    </>
                  ) : (
                    <section className="table-container">
                      <table className="i-table">
                        <thead className="table-head">
                          <tr>
                            <th className="bold-text">
                              {/* Select or deselect all */}
                              <CustomCheckbox
                                checked={selectAll}
                                onChange={handleSelectAll}
                                color="primary"
                              />
                            </th>

                            {rows.map((row, index) => {
                              if (row.title === "amountIn") {
                                return (
                                  <th
                                    key={index}
                                    className={`bold-text cell-title ${
                                      row.value
                                    } ${
                                      row.value !== "containerNumber"
                                        ? "mobile-hider"
                                        : ""
                                    }`}
                                  >
                                    {t(row.title) +
                                      " " +
                                      selectedSupplier?.currency}
                                  </th>
                                );
                              } else {
                                return (
                                  <th
                                    key={index}
                                    className={`bold-text cell-title ${
                                      row.value
                                    } ${
                                      row.value !== "containerNumber"
                                        ? "mobile-hider"
                                        : ""
                                    }`}
                                  >
                                    {t(row.title)}
                                  </th>
                                );
                              }
                            })}
                          </tr>
                        </thead>

                        <tbody className="table-body">
                          {/* These are the existing rows */}
                          {shippedContainers?.map(
                            (row, index) =>
                              !row.canceled && (
                                <tr key={index}>
                                  <td
                                    className={`cell ${
                                      currentDirection === "ltr"
                                        ? "rounded-first-cell"
                                        : "rounded-last-cell"
                                    }`}
                                  >
                                    <CustomCheckbox
                                      checked={row.selected || false}
                                      onChange={(e) => {
                                        const updatedRow = [
                                          ...shippedContainers,
                                        ];
                                        updatedRow[index].selected =
                                          e.target.checked;
                                        setShippedContainers(updatedRow);
                                        console.log("row.selected: ", row);
                                      }}
                                    />
                                  </td>

                                  <td className="cell invoice-number">
                                    <CustomLink
                                      to={`/shippedContainer/${row?.id}`}
                                    >
                                      <p className="link">
                                        {row?.containerNumber}
                                      </p>
                                      {/* {row?.updateCounter > 0 &&
                                        !row?.archived && (
                                          <p className="update-note">
                                            {t("invoiceHasBeenUpdated")}
                                          </p>
                                        )} */}
                                    </CustomLink>
                                  </td>
                                  <td className="cell mobile-hider">
                                    {row?.user?.name ||
                                      row?.user?.username ||
                                      "-"}
                                  </td>

                                  <td className="cell mobile-hider">
                                    {row?.expectedArrivalDate
                                      ? formatDate(row?.expectedArrivalDate)
                                      : "-"}
                                  </td>

                                  <td className="cell mobile-hider">
                                    {row?.finalDestination || "-"}
                                  </td>

                                  <td className="cell mobile-hider">
                                    {row?.customer?.name}
                                    {/* {customers.find(
                                      (customer) =>
                                        customer.id === row?.customerId
                                    )?.name || "-"} */}
                                  </td>

                                  <td className="cell mobile-hider">
                                    {row?.shipper || "-"}
                                  </td>

                                  <td className="cell mobile-hider">
                                    {row?.material || "-"}
                                  </td>

                                  <td className="cell mobile-hider">
                                    {row?.numberOfBoxes || "-"}
                                  </td>

                                  <td className="cell mobile-hider">
                                    {row?.exitDate
                                      ? formatDate(row?.exitDate)
                                      : "-"}
                                  </td>

                                  <td className="cell mobile-hider">
                                    {row?.supplier?.name || "-"}
                                  </td>

                                  <td className="cell mobile-hider">
                                    {row?.dateOfEntry
                                      ? formatDate(row?.dateOfEntry)
                                      : "-"}
                                  </td>
                                  <td className="cell mobile-hider">
                                    {row?.shippingDate
                                      ? formatDate(row?.shippingDate)
                                      : "-"}
                                  </td>
                                  <td className="cell mobile-hider">
                                    {row?.permitsReceivingDate
                                      ? formatDate(row?.permitsReceivingDate)
                                      : "-"}
                                  </td>
                                  <td className="cell mobile-hider">
                                    {row?.subCompany?.name || "-"}
                                    {/* {JSON.stringify(row)} */}
                                  </td>

                                  <td className="cell mobile-hider">
                                    {toMoney(row?.amountForSupplier) || "-"}
                                  </td>

                                  <td className="cell mobile-hider">
                                    {toMoney(row?.abstractAmountForCustomer) ||
                                      "-"}
                                  </td>

                                  <td className="cell mobile-hider">
                                    {selectedSupplier?.currency || "-"}
                                  </td>
                                  <td className="cell mobile-hider">
                                    {toMoney(row?.finalAmountForCustomer) ||
                                      "-"}
                                  </td>
                                  <td className="cell mobile-hider">
                                    {selectedSupplier?.currency || "-"}
                                  </td>

                                  <td
                                    className={`cell notes-cell mobile-hider ${
                                      currentDirection === "ltr"
                                        ? "rounded-last-cell"
                                        : "rounded-first-cell"
                                    }`}
                                  >
                                    {row?.notes || "-"}
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    </section>
                  )}

                  <section className="pagination">
                    <div className="pagination-buttons">
                      <CustomButton
                        variant="blank"
                        title={t("previous")}
                        onClick={() =>
                          setShippedContainersCurrentPage(
                            shippedContainersCurrentPage - 1
                          )
                        }
                        disabled={shippedContainersCurrentPage === 1}
                        icon={
                          currentDirection === "ltr" ? previousIcon : nextIcon
                        }
                        iconSize={15}
                      />
                      <span className="pagination-location">
                        {t("page")}: {shippedContainersCurrentPage} -{" "}
                        {shippedContainersTotalPages}
                      </span>
                      <CustomButton
                        title={t("next")}
                        variant="blank"
                        onClick={() =>
                          setShippedContainersCurrentPage(
                            shippedContainersCurrentPage + 1
                          )
                        }
                        disabled={
                          shippedContainersCurrentPage ===
                          shippedContainersTotalPages
                        }
                        icon={
                          currentDirection === "ltr" ? nextIcon : previousIcon
                        }
                        iconSize={15}
                        reverseIcon
                      />
                    </div>

                    <div className="pagination-buttons">
                      <span className="pagination-location">
                        {t("totalRecords")}: {shippedContainersCount}
                      </span>
                    </div>
                  </section>

                  {/* {selectedSupplier?.id && selectedCurrency?.id && (
                    <div className="balances">
                      <p className="balance">
                        <span>{t("customerCurrentBalance")}: </span>
                        <span className={balanceStyle}>
                          {toMoney(currentBalance?.value)}{" "}
                          {selectedSupplier?.currency}
                        </span>
                      </p>
                      <p className="balance">
                        <span>{t("supplierCurrentBalance")}: </span>
                        <span className={balanceStyle}>
                          {toMoney(currentBalance?.value)}{" "}
                          {selectedSupplier?.currency}
                        </span>
                      </p>
                    </div>
                  )} */}
                </>
              )}
            </>
          ) : null}


          {loading ? (
            <Loader />
          ) : (
            <>
              {!selectedSupplier?.id && (
                <div className="full-w-button-container">
                  <div className="column-flex-box flex-center-center">
                    <InformationBox>{t("noSuppliersAddOne")}</InformationBox>
                    <CustomButton
                      title={t("goToSuppliersPage")}
                      minWidth={140}
                      variant="blank"
                      elementType={Link}
                      to={"/viewSuppliersPage"}
                    />
                  </div>
                </div>
              )}
            </>
          )}

          
          {supplierModal && (
            <Modal
              title="addSupplier"
              onClose={() => handleCancel("supplier")}
              footer={
                <>
                  {loadingModal ? <Loader /> : null}
                  <section className={classes.actions}>
                    <CustomButton
                      onClick={handleAddSupplier}
                      title={t("confirm")}
                      icon={saveIcon}
                      iconSize={16}
                      disabled={loadingModal}
                    />
                    <CustomButton
                      title={t("cancel")}
                      onClick={() => handleCancel("supplier")}
                      variant="blank"
                    />
                  </section>
                </>
              }
            >
              <div className={classes.flexableForm}>
                <section className={classes.section}>
                  <div className={classes.inputContainer}>
                    <span
                      className={
                        currentDirection === "rtl" ? classes.shortSpan : ""
                      }
                    >
                      {t("supplierName")}:{" "}
                    </span>
                    <CustomInput
                      name="supplierName"
                      value={supplierFields?.supplierName}
                      onChange={(e) => handleInputChange(e, "supplier")}
                      flex={true}
                      errorText={
                        error === "noSupplierName" ? t("noSupplierName") : null
                      }
                    />
                  </div>
                </section>
                <section className={classes.section}>
                  <div className={classes.inputContainer}>
                    <span
                      className={
                        currentDirection === "rtl" ? classes.shortSpan : ""
                      }
                    >
                      {t("supplierCompany")}:{" "}
                    </span>
                    <CustomInput
                      name="supplierCompany"
                      value={supplierFields?.supplierCompany}
                      onChange={(e) => handleInputChange(e, "supplier")}
                      flex={true}
                    />
                  </div>
                </section>
                <section className={classes.section}>
                  <div className={classes.inputContainer}>
                    <span
                      className={
                        currentDirection === "rtl" ? classes.shortSpan : ""
                      }
                    >
                      {t("supplierLocation")}:{" "}
                    </span>
                    <CustomInput
                      name="supplierLocation"
                      value={supplierFields?.supplierLocation}
                      onChange={(e) => handleInputChange(e, "supplier")}
                      flex={true}
                    />
                  </div>
                </section>
                <section className={classes.section}>
                  <div className={classes.inputContainer}>
                    <span
                      className={
                        currentDirection === "rtl" ? classes.shortSpan : ""
                      }
                    >
                      {t("supplierCurrency")}:{" "}
                    </span>
                    <Dropdown
                      options={currencies}
                      selected={supplierCurrency}
                      setSelected={setSupplierCurrency}
                      thick
                      flex
                    />
                  </div>
                </section>
              </div>
            </Modal>
          )}
          {/* Render the ChartModal component */}
          {/* <ChartModal
            open={chartModalOpen}
            onClose={handleCloseChart}
            data={data}
          /> */}
        </div>
      </main>

      {showInputFields && (
        <Modal
          title="addShippedContainer"
          onClose={handleCancel}
          footer={
            <>
              {loadingModal ? <Loader /> : null}
              <section className={classes.actions}>
                <CustomButton
                  onClick={handleAddRow}
                  title={t("confirm")}
                  icon={saveIcon}
                  iconSize={16}
                  disabled={loadingModal}
                />
                <CustomButton
                  title={t("cancel")}
                  onClick={handleCancel}
                  variant="blank"
                  icon={closeIcon}
                  iconSize={14}
                />
              </section>
            </>
          }
        >
          <div className={classes.flexableForm}>
            <section>
              <div className={classes.inputContainer}>
                <span>{t("supplier")}: </span>
                <Dropdown
                  options2={suppliers}
                  selected={
                    selectedSupplier.id !== "All"
                      ? selectedSupplier
                      : suppliers[0]
                  }
                  setSelected={handleSelectSupplier}
                  thick
                  flex
                  page="shippedContainers"
                  getData={getSuppliers}
                  errorText={error}
                />
              </div>
            </section>
            <section>
              <div className={classes.inputContainer}>
                <span>{t("customer")}: </span>
                <div className={classes.invoiceNumberContainer}>
                  {selectedCustomer?.id ? (
                    <Dropdown
                      options2={customers}
                      selected={
                        selectedCustomer.id !== "All"
                          ? selectedCustomer
                          : customers[0]
                      }
                      setSelected={handleSelectCustomer}
                      thick
                      flex={true}
                      errorText={error}
                    />
                  ) : (
                    <span>{t("noCustomersFound")}</span>
                  )}
                </div>
              </div>
            </section>
            <section className={`${classes.section} ${classes.invoiceNumber}`}>
              <div className={classes.inputContainer}>
                <span>{t("Container number")}: </span>
                <div className={classes.invoiceNumberContainer}>
                  {dublicatedContainerNumber && (
                    <div className="error-message">
                      {t("dublicatedContainerNumber")}
                    </div>
                  )}
                  <CustomInput
                    name="containerNumber"
                    value={getCleanedInvoiceNumber(
                      newRecord?.containerNumber || ""
                    )}
                    onChange={handleInputChange}
                    flex={true}
                    errorText={error}
                  />
                </div>
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("Expected arrival date")}: </span>
                <CustomInput
                  name="expectedArrivalDate"
                  type="date"
                  flex={true}
                  value={
                    newRecord?.expectedArrivalDate
                      ? newRecord.expectedArrivalDate
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                  onChange={(date) => handleDateChange(date)}
                />
              </div>
            </section>
            <section className={`${classes.section} ${classes.invoiceNumber}`}>
              <div className={classes.inputContainer}>
                <span>{t("Final destination")}: </span>
                <div className={classes.invoiceNumberContainer}>
                  <CustomInput
                    name="finalDestination"
                    value={newRecord?.finalDestination}
                    onChange={handleInputChange}
                    flex={true}
                    errorText={error}
                  />
                </div>
              </div>
            </section>
            <section className={`${classes.section} ${classes.invoiceNumber}`}>
              <div className={classes.inputContainer}>
                <span>{t("Shipper")}: </span>
                <div className={classes.invoiceNumberContainer}>
                  <CustomInput
                    name="shipper"
                    value={newRecord?.shipper}
                    onChange={handleInputChange}
                    flex={true}
                    errorText={error}
                  />
                </div>
              </div>
            </section>
            <section className={`${classes.section} ${classes.invoiceNumber}`}>
              <div className={classes.inputContainer}>
                <span>{t("Material")}: </span>
                <div className={classes.invoiceNumberContainer}>
                  <CustomInput
                    name="material"
                    value={newRecord?.material}
                    onChange={handleInputChange}
                    flex={true}
                    errorText={error}
                  />
                </div>
              </div>
            </section>

            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("Number of boxes")}: </span>
                <CustomInput
                  name="numberOfBoxes"
                  value={newRecord?.numberOfBoxes || ""}
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyDown(e)}
                  type="money"
                  flex={true}
                  errorText={error}
                />
              </div>
            </section>

            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("Exit date")}: </span>
                <CustomInput
                  name="exitDate"
                  type="date"
                  flex={true}
                  value={
                    newRecord?.exitDate
                      ? newRecord.exitDate.toISOString().split("T")[0]
                      : ""
                  }
                  onChange={(date) => handleDateChange(date)}
                />
              </div>
            </section>

            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("Date of entry")}: </span>
                <CustomInput
                  name="dateOfEntry"
                  type="date"
                  flex={true}
                  value={
                    newRecord?.dateOfEntry
                      ? newRecord.dateOfEntry.toISOString().split("T")[0]
                      : ""
                  }
                  onChange={(date) => handleDateChange(date)}
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("Shipping date")}: </span>
                <CustomInput
                  name="shippingDate"
                  type="date"
                  flex={true}
                  value={
                    newRecord?.shippingDate
                      ? newRecord.shippingDate.toISOString().split("T")[0]
                      : ""
                  }
                  onChange={(date) => handleDateChange(date)}
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("Permits receiving date")}: </span>
                <CustomInput
                  name="permitsReceivingDate"
                  type="date"
                  flex={true}
                  value={
                    newRecord?.permitsReceivingDate
                      ? newRecord.permitsReceivingDate
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                  onChange={(date) => handleDateChange(date)}
                />
              </div>
            </section>

            <section className={`${classes.section} ${classes.invoiceNumber}`}>
              <div className={classes.inputContainer}>
                <span>{t("Sub company")}: </span>
                <div className={classes.invoiceNumberContainer}>
                  {subCompanies.length ? (
                    <Dropdown
                      options2={subCompanies}
                      selected={selectedSubCompany}
                      setSelected={handleSelectSubCompany}
                      // thick
                      flex={true}
                    />
                  ) : (
                    <span>{t("noSubCompaniesFound")}</span>
                  )}
                </div>
              </div>
            </section>

            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("Amount for supplier")}: </span>
                <CustomInput
                  name="amountForSupplier"
                  value={newRecord?.amountForSupplier}
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyDown(e)}
                  type="money"
                  flex={true}
                  errorText={error}
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("Abstract amount for customer")}: </span>
                <CustomInput
                  name="abstractAmountForCustomer"
                  value={newRecord?.abstractAmountForCustomer}
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyDown(e)}
                  type="money"
                  flex={true}
                  errorText={error}
                />
              </div>
            </section>

            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("Final amount for customer")}: </span>
                <CustomInput
                  name="finalAmountForCustomer"
                  value={newRecord?.finalAmountForCustomer}
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyDown(e)}
                  type="money"
                  flex={true}
                  errorText={error}
                />
              </div>
            </section>

            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span className={`${classes.notesSpan}`}>{t("Notes")}: </span>
                <CustomInput
                  name="notes"
                  type="textarea"
                  value={newRecord?.notes || ""}
                  onChange={handleInputChange}
                  flex={true}
                />
              </div>
            </section>
            {/* <PDFUploader
              ref={pdfUploaderRef}
              invoiceNumber={data?.invoiceNumber}
              invoiceDocs={invoiceDocs}
              setInvoiceDocs={setInvoiceDocs}
              mode="upload"
              documents={data?.documents}
              tempDocs={invoiceDocs}
              setTempDocs={setInvoiceDocs}
            /> */}
          </div>
        </Modal>
      )}
    </>
  );
};

export default OrgShippedContainers;
