// im-app/src/components/InoviceTable.js
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import "../InvoicesManagement.scss";
import ChartModal from "../../components/ChartModal";
import CustomInput from "../../components/CustomInput";
import { toMoney } from "../../utils/toMoney";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/CustomButton";

import invoicesIcon from "../../assets/invoices.png";
import addIconLight from "../../assets/add_icon_light.png";
import searchIcon from "../../assets/search_icon.png";
import nextIcon from "../../assets/next_icon.png";
import previousIcon from "../../assets/previous_icon.png";
import saveIcon from "../../assets/save_icon.png";
import closeIcon from "../../assets/close_icon.png";
import infoIcon from "../../assets/information.png";
import printIcon from "../../assets/printer_icon.png";

import Header from "../../components/Header";
import UserSection from "../../components/UserSection";
import { DataContext } from "../../utils/DataContext";
import InformationBox from "../../components/InformationBox";
import Modal from "../../components/Modal";
import classes from "../../components/Modal.module.css";
import useLanguage from "../../utils/useLanguage";
import { sanitizeNumber } from "../../utils/sanitizeNumber";
import Dropdown from "../../components/Dropdown";
import NetworkError from "../../components/NetworkError";
import BackToDashboard from "../../components/BackToDashboard";
import Loader from "../../components/Loader";

const OrgContainerPrices = () => {
  const navigate = useNavigate();

  // New state variables for the row modal
  const [selectedRow, setSelectedRow] = useState(null);
  const [showRowModal, setShowRowModal] = useState(false);

  // Handler for opening the row info modal
  const handleRowButtonClick = (row) => {
    setSelectedRow(row);
    setShowRowModal(true);
  };

  // Handler for closing the row info modal
  const handleCloseRowModal = () => {
    setSelectedRow(null);
    setShowRowModal(false);
  };

  const handlePrint = () => {
    const direction = currentDirection === "rtl" ? "rtl" : "ltr";
    const textAlign = currentDirection === "rtl" ? "right" : "left";

    // Determine color based on the relationship between balanceBeforeUpdate and balanceAfterUpdate
    const amountColor =
      selectedRow?.balanceAfterUpdate < selectedRow?.balanceBeforeUpdate
        ? "red"
        : "green";

    const printContent = `
      <div style="padding: 20px; font-family: Arial, sans-serif;" dir="${direction}">
        <h2 style="text-align: ${textAlign};">${t("BalanceInformation")}</h2>
        
        <table style="width: 100%; text-align: ${textAlign}; border-collapse: collapse;">
          <!-- Supplier Information -->
          <tr>
            <td style="padding: 8px; vertical-align: top;">
              <strong>${t("supplier")}:</strong>
            </td>
            <td style="padding: 8px; vertical-align: top;">
              ${selectedSupplier?.name || t("No supplier available")}
            </td>
          </tr>
          
          <!-- Balance Before Update -->
          <tr>
            <td style="padding: 8px; vertical-align: top;">
              <strong>${t("Balance Before Update")}:</strong>
            </td>
            <td style="padding: 8px; vertical-align: top;">
              ${toMoney(selectedRow?.balanceBeforeUpdate)}
            </td>
          </tr>
          
          <!-- Container's Prices -->
          <tr>
            <td style="padding: 8px; vertical-align: top;">
              <strong>${t("Container's prices")}:</strong>
            </td>
            <td style="padding: 8px; vertical-align: top;">
              <span style="color: ${amountColor};">
                ${toMoney(selectedRow?.amountInSupCur)}
              </span>
            </td>
          </tr>
          
          <!-- Balance After Update -->
          <tr>
            <td style="padding: 8px; vertical-align: top;">
              <strong>${t("Balance After Update")}:</strong>
            </td>
            <td style="padding: 8px; vertical-align: top;">
              <span style="color: ${
                selectedRow?.balanceAfterUpdate >= 0 ? "green" : "red"
              };">
                ${toMoney(selectedRow?.balanceAfterUpdate)}
              </span>
            </td>
          </tr>
          
          <!-- Date -->
          <tr>
            <td style="padding: 8px; vertical-align: top;">
              <strong>${t("Date")}:</strong>
            </td>
            <td style="padding: 8px; vertical-align: top;">
              ${
                selectedRow?.modifiedAt
                  ? new Date(selectedRow.modifiedAt).toISOString().split("T")[0]
                  : t("No date available")
              }
            </td>
          </tr>
          
          <!-- Notes -->
          <tr>
            <td style="padding: 8px; vertical-align: top;">
              <strong>${t("Notes")}:</strong>
            </td>
            <td style="padding: 8px; vertical-align: top;">
              ${selectedRow?.notes || t("No notes available")}
            </td>
          </tr>
        </table>
      </div>
    `;

    const newWindow = window.open("", "", "width=800,height=600");
    newWindow.document.write(`
      <html dir="${direction}">
      <head>
        <title>${t("Print")}</title>
        <style>
          body { font-family: Arial, sans-serif; direction: ${direction}; }
          h2 { color: #333; text-align: ${textAlign}; }
          table { width: 100%; border-collapse: collapse; }
          td { padding: 8px; vertical-align: top; }
          strong { color: #555; }
        </style>
      </head>
      <body>
        ${printContent}
      </body>
      </html>
    `);
    newWindow.document.close();
    newWindow.print();
  };

  const rows = [
    {
      title: "Balance Before Update",
      value: "balance",
    },
    {
      title: "Container's prices",
      value: "containersPricesInRMB",
    },
    {
      title: "Balance After Update",
      value: "balance",
    },
    {
      title: "Date",
      value: "date",
    },
    {
      title: "Notes",
      value: "notes",
    },
    {
      title: "print",
      value: "print",
    },
  ];

  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();

  const [data, setData] = useState([]);
  const [newRow, setNewRow] = useState({
    balance: 0,
    containersPricesInRMB: 0,
    currentBalance: 0,
    date: new Date(),
    notes: "",
  });

  // const [error, setError] = useState("");
  const [showInputFields, setShowInputFields] = useState(false);
  const [showAddRowButton, setShowAddRowButton] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);
  const [chartModalOpen, setChartModalOpen] = useState(false);
  const [tempCalc, setTempCalc] = useState(0);

  const [containersCurrentPage, setContainersCurrentPage] = useState(1);
  const [containersTotalPages, setContainersTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);

  const {
    selectedSupplier,
    currentBalance,
    setCurrentBalance,
    supplierModal,
    setSupplierModal,
    handleSaveSupplier,
    supplierFields,
    setSupplierFields,
    supplierCurrency,
    setSupplierCurrency,
    currencies,
    suppliers,
    getSuppliers,
    handleSelectSupplier,
    error,
    setError,
    networkError,
    loading,
    setLoading,
    pageLoading,
    setPageLoading,
    setNetworkError,
    fetchCurrentBalance,
    setSelectedSupplier,
  } = useContext(DataContext);

  const handleAddSupplier = async () => {
    setLoadingModal(true);
    await handleSaveSupplier();
    setLoadingModal(false);
  };

  const balanceStyle =
    currentBalance?.value >= 0 ? "positive-value" : "negative-value";

  useEffect(() => {
    fetchContainers();
  }, []);

  useEffect(() => {
    if (selectedSupplier) {
      const getCurrentBalance = async () => await fetchCurrentBalance();

      getCurrentBalance();
    }
  }, [suppliers]);

  useEffect(() => {
    if (selectedSupplier?.id) {
      fetchContainers();
    } else {
      // setLoading(false);
    }
  }, [selectedSupplier]);

  useEffect(() => {
    const getNewContainersPage = async () => await fetchContainers();
    if (selectedSupplier?.id) {
      getNewContainersPage();
    }
  }, [containersCurrentPage]);

  const fetchContainers = async () => {
    setPageLoading((prev) => ({
      ...prev,
      isPending: true,
    }));
    try {
      const token = localStorage.getItem("token");
      // Ensure userId is stored as a number
      const userId = parseInt(localStorage.getItem("userId"), 10);

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/getContainersPrices`,
        {
          params: { page: containersCurrentPage, limit: 10 },
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
            "Supplier-Id": selectedSupplier?.id,
          },
        }
      );

      if (response) {
        setData(response?.data?.data);
        setTotalRecords(response?.data?.pagination?.totalRecords);
        setContainersTotalPages(response?.data?.pagination?.totalPages);
        // setLoading(false);
        setNewRow((prev) => {
          const lastElement = response?.data?.[response.data.length - 1];
          return {
            ...prev,
            balance: lastElement?.currentBalance || 0,
          };
        });
      }

      const resCurrentSupplier = response?.data?.currentSupplier;

      if (resCurrentSupplier) {
        setSelectedSupplier(resCurrentSupplier);
        // localStorage.setItem(
        //   "currentSupplier",
        //   JSON.stringify(resCurrentSupplier)
        // );
      }

      if (response?.status === 200) {
        setPageLoading((prev) => ({
          ...prev,
          isPending: false,
          isSuccess: true,
        }));
      } else {
        setPageLoading((prev) => ({
          ...prev,
          isPending: false,
          isFailed: true,
        }));
      }
    } catch (error) {
      // setLoading(false);
      setNetworkError(true);
      console.error("Error fetching data:", error);
    } finally {
      setPageLoading((prev) => ({
        ...prev,
        isPending: false,
        isFailed: true,
      }));
    }
  };

  const handleCancel = (modal) => {
    if (modal === "supplier") {
      setSupplierModal(false);
    } else {
      setNewRow({
        balance: 0,
        containersPricesInRMB: 0,
        currentBalance: 0,
        date: new Date(),
        notes: "",
      });
      setShowInputFields(false);
    }
  };

  const handleShowChart = () => {
    setChartModalOpen(true);
  };

  const handleCloseChart = () => {
    setChartModalOpen(false);
  };

  const handleInputChange = (e, modal) => {
    const { name, value } = e.target;

    if (modal === "supplier") {
      setSupplierFields((prev) => ({ ...prev, [name]: value }));
    } else {
      if (name === "containersPricesInRMB") {
        setTempCalc(currentBalance?.value - sanitizeNumber(value));
      }
      setNewRow((prevRow) => {
        let updatedRow = { ...prevRow, [name]: value };

        return updatedRow;
      });
    }
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    setNewRow((prevRow) => ({ ...prevRow, date: new Date(date) }));
  };

  const handleAddRow = async () => {
    if (!selectedSupplier?.id) {
      setError("noSupplier");
      return;
    } else {
      if (!newRow?.containersPricesInRMB) {
        setError("asdf");
        return;
      }
      const balanceBeforeUpdate = currentBalance?.value;

      const calcCurrentBalance =
        // currentBalance?.value - newRow?.containersPricesInRMB;
        currentBalance?.value - sanitizeNumber(newRow?.containersPricesInRMB);

      setCurrentBalance((prev) => ({ ...prev, value: calcCurrentBalance }));
      setNewRow((prev) => ({ ...prev, currentBalance: calcCurrentBalance }));

      try {
        const token = localStorage.getItem("token");
        const userId = parseInt(localStorage.getItem("userId"), 10);

        if (!userId) {
          console.error("User ID not found in localStorage");
          return;
        }

        const data = {
          ...newRow,
          containersPricesInRMB: parseFloat(
            sanitizeNumber(newRow?.containersPricesInRMB)
            // newRow?.containersPricesInRMB
          ),
          currentBalance: {
            containerId: currentBalance.containerId,
            value: calcCurrentBalance,
          },
          userId,
          supplierId: selectedSupplier?.id,
          balanceBeforeUpdate,
          balanceAfterUpdate: calcCurrentBalance,

          // balance: 0,
          // containersPricesInRMB: 0,
          // currentBalance: 0,
          // date: new Date(),
          // notes: "",
        };
        setLoadingModal(true);
        const res = await axios.post(
          `${process.env.REACT_APP_URL}/createContainerPrices`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "User-Id": userId,
            },
          }
        );

        if (res.data) {
          fetchContainers();
          setError("");
          setShowInputFields(false);
          setNewRow((prev) => ({
            ...prev,
            containersPricesInRMB: 0,
            date: new Date(),
            notes: "",
          }));
          setTotalRecords((prev) => prev + 1);
        }
        setLoadingModal(false);
      } catch (error) {
        setNetworkError(true);
        console.error("Error adding new invoice:", error);
        setError("addContainerFailed");
        setLoadingModal(false);
      }
    }
  };

  const handleShowInputFields = () => {
    setShowInputFields(true);
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const handleSearch = () => {
    if (!selectedDate) {
      return data; // Return all data if no date is selected
    }

    const lowerCaseQuery = selectedDate.toLocaleDateString("en-CA"); // Format the selected date for comparison

    const filteredData = data.filter((row) => {
      return Object.values(row).some((value) => {
        if (
          typeof value === "string" &&
          value.toLowerCase().includes(lowerCaseQuery)
        ) {
          return true; // Match found in string values
        }

        // Check if the value is a date
        const dateValue = new Date(value);
        if (!isNaN(dateValue)) {
          // Format the date to a comparable format (e.g., YYYY-MM-DD)
          const formattedDate = dateValue.toISOString().split("T")[0];
          return formattedDate.includes(lowerCaseQuery); // Check if the formatted date matches
        }

        return false; // Return false for non-matching fields
      });
    });

    return filteredData;
  };

  // if (networkError) {
  //   return (
  //     <main className="main">
  //       <div className="invoice-table-container">
  //         <NetworkError>{t("networkError")}</NetworkError>
  //       </div>
  //     </main>
  //   );
  // }

  // Prevent entering numbers in input field
  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
      "Enter",
    ];
    const isNumberOrSymbol = /\d|\,|\./.test(e.key);

    if (!isNumberOrSymbol && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  // if (pageLoading?.isPending) {
  //   return <Loader fullHeight />;
  // }

  return (
    <>
      <main className="e-container">
        <div>
          <BackToDashboard
            title={t("back")}
            variant="blank"
            icon={currentDirection === "ltr" ? previousIcon : nextIcon}
            iconSize={14}
          />
        </div>

        <div className="invoice-table-container">
          <UserSection
            handleShowChart={handleShowChart}
            page="containerPrices"
          />

          <div className="user-section">
            <div className="page-title">{t("containersPage")}</div>
            <div className="o-row responsive-box">
              <div className="navigation-button-container">
                <CustomButton
                  elementType={Link}
                  title={t("invoicesPage")}
                  to="/orgInvoiceManagement"
                  icon={invoicesIcon}
                />
              </div>
            </div>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              {selectedSupplier?.id ? (
                <>
                  <div className="search-bar">
                    <label>{t("search")}: </label>

                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => {
                        setSelectedDate(date);
                      }} // Update the selected date
                      dateFormat="yyyy-MM-dd"
                      placeholderText={t("searchByDate")}
                    />

                    <CustomButton
                      title={t("search")}
                      onClick={handleSearch}
                      icon={searchIcon}
                      iconSize={15}
                    />
                  </div>
                  <div className="top-action-buttons">
                    {showAddRowButton ? (
                      <div className="button-group">
                        <CustomButton
                          title={t("addContainer")}
                          onClick={handleShowInputFields}
                          icon={addIconLight}
                          iconSize={20}
                        />
                      </div>
                    ) : null}
                  </div>

                  {data.length === 0 && !showInputFields ? (
                    <InformationBox>{t("noTransactions")}</InformationBox>
                  ) : (
                    <TableContainer component={Paper}>
                      <Table className="c-table">
                        <TableHead>
                          <TableRow>
                            {rows.map((row, index) => {
                              if (row.title === "Container's prices") {
                                return (
                                  <TableCell
                                    key={index}
                                    className="bold-text  cell-title"
                                  >
                                    {t(row.title)}
                                    <br />
                                    {t("in")} {selectedSupplier?.currency}
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell
                                    key={index}
                                    className="bold-text  cell-title"
                                  >
                                    {t(row.title)}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        </TableHead>

                        <TableBody className="table-body">
                          {/* These are the existing rows */}
                          {handleSearch()?.map((row, index) => (
                            <TableRow
                              key={index}
                              className={
                                row.canceled || row.archived
                                  ? "canceled-invoice"
                                  : "updated-invoice"
                              }
                            >
                              <TableCell className="cell">
                                {toMoney(row.balanceBeforeUpdate)}
                              </TableCell>
                              <TableCell className="cell">
                                <span
                                  className={
                                    row?.invoiceNumber ? "invoice" : "container"
                                  }
                                >
                                  {toMoney(row?.amountInSupCur)}
                                </span>
                              </TableCell>
                              <TableCell className="cell">
                                {toMoney(row?.balanceAfterUpdate)}
                              </TableCell>
                              <TableCell
                                className="cell"
                                style={{ height: "50px", minWidth: "145px" }}
                              >
                                {row.date || row.modifiedAt
                                  ? new Date(row.date || row.modifiedAt)
                                      .toISOString()
                                      .split("T")[0]
                                  : ""}
                              </TableCell>

                              <TableCell className="notes-cell">
                                {row.canceled === true ? (
                                  <div className="notes-cell-div">
                                    <div className="notes-cell-pair-container">
                                      <p className="notes-bold">
                                        {t("Invoice Number")}:
                                      </p>
                                      <p>
                                        {row?.invoiceNumber.replace(
                                          /\s*Updated\s*\(\d+\)\s*$/,
                                          ""
                                        )}
                                      </p>
                                    </div>
                                    <div className="notes-cell-pair-container">
                                      <p className="notes-bold">
                                        {t("updatedOn")}:
                                      </p>
                                      <p className="notes-modifiedDate">
                                        {new Date(
                                          row?.modifiedAt
                                        )?.toLocaleDateString()}
                                      </p>
                                    </div>
                                    <p className="notes-bold">
                                      {t("otherNotes")}:
                                    </p>
                                    <p>{row?.notes}</p>
                                  </div>
                                ) : row?.invoiceNumber ? (
                                  <div className="notes-cell-div">
                                    <div className="notes-cell-pair-container">
                                      <p className="notes-bold">
                                        {t("Invoice Number")}:
                                      </p>
                                      <p>
                                        {row?.invoiceNumber?.replace(
                                          /\s*Updated\s*\(\d+\)\s*$/,
                                          ""
                                        )}
                                      </p>
                                    </div>
                                    <p className="notes-bold">
                                      {t("otherNotes")}:
                                    </p>
                                    <p>{row?.notes}</p>
                                  </div>
                                ) : (
                                  <div className="notes-cell-div">
                                    <p>{row.notes}</p>
                                  </div>
                                )}
                              </TableCell>

                              {/* New TableCell for the action button */}
                              <TableCell className="cell">
                                <CustomButton
                                  variant="blank"
                                  title={t("ViewInfo")}
                                  onClick={() => handleRowButtonClick(row)}
                                  icon={infoIcon}
                                  iconSize={15}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}

                  <section className="pagination">
                    <div className="pagination-buttons">
                      <CustomButton
                        variant="blank"
                        title={t("previous")}
                        onClick={() =>
                          setContainersCurrentPage((prev) => prev - 1)
                        }
                        disabled={containersCurrentPage === 1}
                        icon={
                          currentDirection === "ltr" ? previousIcon : nextIcon
                        }
                        iconSize={15}
                      />
                      <span className="pagination-location">
                        {t("page")}: {containersCurrentPage} -{" "}
                        {containersTotalPages}
                      </span>
                      <CustomButton
                        title={t("next")}
                        variant="blank"
                        onClick={() =>
                          setContainersCurrentPage((prev) => prev + 1)
                        }
                        disabled={
                          containersCurrentPage === containersTotalPages
                        }
                        icon={
                          currentDirection === "ltr" ? nextIcon : previousIcon
                        }
                        iconSize={15}
                        reverseIcon
                      />
                    </div>

                    <div className="pagination-buttons">
                      <span className="pagination-location">
                        {t("totalRecords")}: {totalRecords}
                      </span>
                    </div>
                  </section>

                  {selectedSupplier?.id !== "All" && (
                    <p className="current-balance">
                      {t("currentBalance")}:{" "}
                      <span className={balanceStyle}>
                        {toMoney(currentBalance?.value)}{" "}
                        {selectedSupplier?.currency}
                      </span>
                    </p>
                  )}
                </>
              ) : (
                <div className="full-w-button-container">
                  <div className="column-flex-box flex-center-center">
                    <InformationBox>{t("noSuppliersAddOne")}</InformationBox>
                    <CustomButton
                      title={t("goToSuppliersPage")}
                      minWidth={140}
                      variant="blank"
                      elementType={Link}
                      to={"/viewSuppliersPage"}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </main>

      {/* Render the SupplierModal  */}
      {supplierModal && (
        <Modal
          title="addSupplier"
          onClose={() => handleCancel("supplier")}
          footer={
            <>
              {loadingModal ? <Loader /> : null}
              <section className={classes.actions}>
                <CustomButton
                  onClick={handleAddSupplier}
                  title={t("confirm")}
                  icon={saveIcon}
                  iconSize={16}
                  disabled={loadingModal}
                />
                <CustomButton
                  title={t("cancel")}
                  onClick={() => handleCancel("supplier")}
                  variant="blank"
                  icon={closeIcon}
                  iconSize={14}
                />
              </section>
            </>
          }
        >
          <div className={classes.flexableForm}>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span
                  className={
                    currentDirection === "rtl" ? classes.shortSpan : ""
                  }
                >
                  {t("supplierName")}:{" "}
                </span>
                <CustomInput
                  name="supplierName"
                  value={supplierFields?.supplierName}
                  onChange={(e) => handleInputChange(e, "supplier")}
                  flex={true}
                  errorText={
                    error === "noSupplierName" ? t("noSupplierName") : null
                  }
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span
                  className={
                    currentDirection === "rtl" ? classes.shortSpan : ""
                  }
                >
                  {t("supplierCompany")}:{" "}
                </span>
                <CustomInput
                  name="supplierCompany"
                  value={supplierFields?.supplierCompany}
                  onChange={(e) => handleInputChange(e, "supplier")}
                  flex={true}
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span
                  className={
                    currentDirection === "rtl" ? classes.shortSpan : ""
                  }
                >
                  {t("supplierLocation")}:{" "}
                </span>
                <CustomInput
                  name="supplierLocation"
                  value={supplierFields?.supplierLocation}
                  onChange={(e) => handleInputChange(e, "supplier")}
                  flex={true}
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span
                  className={
                    currentDirection === "rtl" ? classes.shortSpan : ""
                  }
                >
                  {t("supplierCurrency")}:{" "}
                </span>

                <Dropdown
                  options={currencies}
                  selected={supplierCurrency}
                  setSelected={setSupplierCurrency}
                  thick
                  flex
                />
              </div>
            </section>
          </div>
        </Modal>
      )}

      {/* Render the ChartModal component */}
      <ChartModal
        open={chartModalOpen}
        onClose={handleCloseChart}
        data={data}
      />

      {/* Render the Printing modal */}
      {showRowModal && selectedRow && (
        <Modal
          title={t("BalanceInformation")}
          onClose={handleCloseRowModal}
          footer={
            <section className={classes.actions}>
              <CustomButton
                title={t("print")}
                onClick={handlePrint}
                variant="gray"
                icon={printIcon}
                iconSize={18}
              />
            </section>
          }
        >
          <div className={classes.flexableForm}>
            {/* Supplier Information */}
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span className={classes.longSpan}>{t("supplier")}:</span>
                <span className={classes.valueText}>
                  {selectedSupplier?.name || t("No supplier available")}
                </span>
              </div>
            </section>

            {/* Balance Before Update */}
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span className={classes.longSpan}>
                  {t("balanceBeforeUpdate")}:
                </span>
                <span className={classes.valueText}>
                  {toMoney(selectedRow?.balanceBeforeUpdate)}
                </span>
              </div>
            </section>

            {/* Container's Prices */}
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span className={classes.longSpan}>
                  {t("Container's prices")}:
                </span>
                <span
                  className={
                    selectedRow?.invoiceNumber ? "invoice" : "container"
                  }
                >
                  {toMoney(selectedRow?.amountInSupCur)}
                </span>
              </div>
            </section>

            {/* Balance After Update */}
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span className={classes.longSpan}>
                  {t("balanceAfterUpdate")}:
                </span>
                <span className={classes.valueText}>
                  {toMoney(selectedRow?.balanceAfterUpdate)}
                </span>
              </div>
            </section>

            {/* Date */}
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span className={classes.longSpan}>{t("Date")}:</span>
                <span className={classes.valueText}>
                  {selectedRow?.date
                    ? new Date(selectedRow.date).toISOString().split("T")[0]
                    : selectedRow?.modifiedAt
                    ? new Date(selectedRow.modifiedAt)
                        .toISOString()
                        .split("T")[0]
                    : t("No date available")}
                </span>
              </div>
            </section>

            {/* Notes */}
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span className={classes.longSpan}>{t("Notes")}:</span>
                <span className={classes.valueText}>
                  {selectedRow?.notes || t("No notes available")}
                </span>
              </div>
            </section>
          </div>
        </Modal>
      )}

      {showInputFields && (
        <Modal
          title="addContainer"
          onClose={handleCancel}
          error={
            error === "noContainersPrices" || error === "addContainerFailed" ? (
              <div className="error-message">{t(error)}</div>
            ) : null
          }
          footer={
            <>
              {loadingModal ? <Loader /> : null}
              <section className={classes.actions}>
                <CustomButton
                  onClick={handleAddRow}
                  title={t("confirm")}
                  icon={saveIcon}
                  iconSize={16}
                  disabled={loadingModal}
                />
                <CustomButton
                  title={t("cancel")}
                  onClick={handleCancel}
                  variant="blank"
                  icon={closeIcon}
                  iconSize={14}
                />
              </section>
            </>
          }
        >
          <div className={classes.flexableForm}>
            <section>
              {error === "noSupplier" && (
                <div className="error-message">{t(error)}</div>
              )}
              <div className={classes.inputContainer}>
                <span className={classes.longSpan}>{t("supplier")}: </span>

                <Dropdown
                  options2={suppliers}
                  selected={selectedSupplier}
                  setSelected={handleSelectSupplier}
                  page="containers"
                  thick
                  flex
                  errorText={error}
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span className={classes.longSpan}>
                  {t("Balance Before Update")}:{" "}
                </span>
                <CustomInput
                  name="balanceBeforeUpdate"
                  value={currentBalance?.value}
                  onChange={handleInputChange}
                  readOnly
                  flex={true}
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span className={classes.longSpan}>
                  {t("Container's prices")}:{" "}
                </span>
                {/* {error === "noContainersPrices" && (
                <div className="error-message">{t(error)}</div>
              )} */}
                <CustomInput
                  name="containersPricesInRMB"
                  value={newRow?.containersPricesInRMB}
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyDown(e)}
                  type="money"
                  flex={true}
                  errorText={error === "noContainersPrices" ? t(error) : null}
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span className={classes.longSpan}>
                  {t("Balance After Update")}:{" "}
                </span>
                <CustomInput
                  name="balanceAfterUpdate"
                  value={tempCalc || currentBalance?.value}
                  onChange={handleInputChange}
                  flex={true}
                />
              </div>
            </section>

            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span className={classes.longSpan}>{t("Date")}: </span>
                <CustomInput
                  type="date"
                  name="date"
                  flex={true}
                  value={
                    newRow?.date.toISOString().split("T")[0] ||
                    newRow?.modifiedAt.toISOString().split("T")[0]
                  }
                  onChange={(date) => handleDateChange(date)}
                />
              </div>
            </section>

            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span className={`${classes.longSpan} ${classes.notesSpan}`}>
                  {t("Notes")}:{" "}
                </span>
                <CustomInput
                  name="notes"
                  type="textarea"
                  value={newRow?.notes}
                  onChange={handleInputChange}
                  flex={true}
                />
              </div>
            </section>
            {error === "noSupplier" && (
              <div className="error-message">{t(error)}</div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default OrgContainerPrices;
