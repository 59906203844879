import { useTranslation } from "react-i18next";
import classes from "./Loader.module.css";

const Loader = ({ fullHeight = false }) => {
  const { t } = useTranslation("general");

  return (
    <div
      className={`${classes.loader} ${
        fullHeight ? classes.fullScreenHeight : ""
      }`}
    >
      <p className={classes.loaderText}>{t("loading")}</p>
    </div>
  );
};

export default Loader;
