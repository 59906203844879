export function toDate(stringDate) {
  return new Date(stringDate).toLocaleDateString();
  // return "ahemed";
}

export function formatDate(date) {
  if (!date) return "-";
  const parsedDate = new Date(date);
  return isNaN(parsedDate.getTime())
    ? "-"
    : parsedDate.toISOString().split("T")[0];
}
