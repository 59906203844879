import { useState, useEffect, useContext } from "react";
import { Container, Typography } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import useLanguage from "../../utils/useLanguage";
import nextIcon from "../../assets/next_icon.png";
import previousIcon from "../../assets/previous_icon.png";
import editIcon from "../../assets/edit_icon.png";
import closeIcon from "../../assets/close_icon.png";
import saveIcon from "../../assets/save_icon.png";
import addIcon from "../../assets/add_icon.png";
import { UserAuth } from "../../utils/AuthContext";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import ErrorBox from "../../components/ErrorBox";
import { DataContext } from "../../utils/DataContext";
import BackToDashboard from "../../components/BackToDashboard";

export default function SubCompanies() {
  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();
  const [filteredSubCompanies, setFilteredSubCompanies] = useState([]);
  // const [networkError, setNetworkError] = useState(false);
  const [subCompanyModal, setSubCompanyModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [subCompanyFields, setSubCompanyFields] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loadingModal, setLoadingModal] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  const [addSubCompanyModal, setAddSubCompanyModal] = useState(false);
  const [newSubCompanyFields, setNewSubCompanyFields] = useState({
    companyName: "",
    location: "",
    website: "",
    email: "",
    phoneNumber: "",
    organizationId: "",
  });

  const { userData } = useContext(UserAuth);
  const {
    getSubCompanies,
    subCompanies,
    setSubCompanies,
    searchTerm,
    setSearchTerm,
    selectedSubCompany,
    setSelectedSubCompany,
    // pageLoading,
    // setPageLoading,
    loading,
    setLoading,
  } = useContext(DataContext);

  useEffect(() => {
    getSubCompanies();
  }, [currentPage, searchTerm, userData]);

  useEffect(() => {
    setFilteredSubCompanies(
      subCompanies?.filter((subCompany) =>
        subCompany?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
      )
    );
  }, [searchTerm, subCompanies]);

  console.log("filteredSubCompanies", filteredSubCompanies);

  const openSubCompanyModal = (subCompany) => {
    setSelectedSubCompany(subCompany);

    console.log("subCompany", subCompany);
    setSubCompanyFields({
      companyName: subCompany.name,
      location: subCompany.location,
      website: subCompany.website,
      email: subCompany.email,
      phoneNumber: subCompany.phoneNumber,
    });

    setIsEditing(false); // Set to editing mode
    setSubCompanyModal(true);
  };

  const handleInputChange = (e, fieldName) => {
    setSubCompanyFields((prev) => ({
      ...prev,
      [fieldName]: e.target.value,
    }));
  };

  const handleSaveSubCompany = async () => {
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);
    console.log("subCompanyFields", subCompanyFields);

    try {
      setLoadingModal(true);
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/updateSubCompany/${selectedSubCompany.id}`,
        subCompanyFields,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "organization-Id": userData?.organizationId,
          },
        }
      );

      if (response.status === 200) {
        await getSubCompanies();
        setSubCompanyModal(false);
        console.log("SubCompany updated successfully");
      } else {
        console.error("Failed to update SubCompany:", response.data.message);
      }
      setLoadingModal(false);
    } catch (error) {
      console.error("Error updating SubCompany:", error);
      setLoadingModal(false);
    }
  };

  const handleCancel = () => {
    setSubCompanyModal(false);
    setSelectedSubCompany(null);
  };

  const openAddSubCompanyModal = () => {
    setAddSubCompanyModal(true);
    setNewSubCompanyFields({
      companyName: "",
    });
  };

  const handleNewSubCompanyInputChange = (e, fieldName) => {
    const value = fieldName === "isBlocked" ? e.target.checked : e.target.value;
    setNewSubCompanyFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  const handleCreateSubCompany = async (e) => {
    e.preventDefault();

    try {
      // Determine the language based on currentDirection
      const language = currentDirection === "rtl" ? "ar" : "en";

      console.log("newSubCompanyFields", newSubCompanyFields);

      //  Empty fields validation

      if (
        !newSubCompanyFields.companyName ||
        !newSubCompanyFields.location ||
        !newSubCompanyFields.phoneNumber
      ) {
        setError(true);
        setErrorMessage(t("emptyInputFields"));
        return;
      }

      // // Email validation regex
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (newSubCompanyFields.email) {
        if (!emailPattern.test(newSubCompanyFields.email)) {
          setError(true);
          setErrorMessage(t("invalidEmail"));
          return;
        }
      }

      setLoadingModal(true);
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/createSubCompany`,
        {
          // email: newUserFields.userEmail,
          ...newSubCompanyFields,
          // password: newUserFields.password,
          // phoneNumber: newUserFields.phoneNumber,
          // isBlocked: newUserFields.isBlocked,
          // isOrganization: true,
          organizationId: Number(userData?.organizationId),
          userId: Number(userData?.userId),
          // supplierId: selectedSupplier?.id,
          language,
        }
      );

      setMessage(response.data.message);
      if (response.data.message === "SubCompany created successfully") {
        await getSubCompanies();
      }

      setError(false);
      setErrorMessage("");
      setAddSubCompanyModal(false);
      setSubCompanies((prev) => [response?.data?.subCompany, ...prev]);
      setLoadingModal(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorKey = error.response.data.error;
        console.log("errorKey:", errorKey);
        // Map backend errors to user-friendly messages
        switch (errorKey) {
          case "emailAlreadyInUse":
            setError(true);
            setErrorMessage(t("emailAlreadyInUse"));
            break;
          case "invalidPhoneNumber":
            setError(true);
            setErrorMessage(t("invalidPhoneNumber"));
            break;
          default:
            setError(true);
            setErrorMessage(t("unexpectedError"));
            break;
        }
      } else {
        console.error("Network or other error:", error.message);
        setError(true);
        setErrorMessage(t("networkError"));
      }
      setLoadingModal(false);
    }
  };

  const handleCancelAddSubCompany = () => {
    setError(false);
    setErrorMessage("");
    setAddSubCompanyModal(false);
  };

  return (
    <div className="e-container">
      <div
        className="view-users-page-container"
        style={{ direction: currentDirection }}
      >
        <div>
          <BackToDashboard
            title={t("back")}
            variant="blank-fit-content"
            icon={currentDirection === "ltr" ? previousIcon : nextIcon}
            iconSize={14}
          />
        </div>

        <div className="users-title-section">
          <h4 className="title">
            {t("subCompanies", { defaultValue: "subCompanies" })}
          </h4>

          {!loading && userData?.userId ? (
            <CustomButton
              title={t("createSubCompany")}
              variant="blank"
              icon={addIcon}
              iconSize={20}
              onClick={openAddSubCompanyModal}
            />
          ) : null}
        </div>

        {/* {!loading && (
         
        )} */}

        {loading ? (
          <Loader />
        ) : (
          <>
            <CustomInput
              name="subCompanyName"
              value={searchTerm}
              placeholder={searchTerm ? "" : t("search")}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            {filteredSubCompanies.length === 0 ? (
              <p>{t("noSubCompaniesFound")}</p>
            ) : (
              <>
                <ul
                  className="users-list"
                  style={{ direction: currentDirection }}
                >
                  {filteredSubCompanies.map((subCompany) => (
                    <li
                      className="user-list-item"
                      key={subCompany.id}
                      onClick={() => openSubCompanyModal(subCompany)}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="user-username">{subCompany?.name}</p>
                    </li>
                  ))}
                </ul>

                <section className="pagination">
                  <div className="pagination-buttons">
                    <CustomButton
                      variant="blank"
                      title={t("previous")}
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                      icon={
                        currentDirection === "ltr" ? previousIcon : nextIcon
                      }
                      iconSize={15}
                    />
                    <span className="pagination-location">
                      {t("page")}: {currentPage} - {totalPages}
                    </span>
                    <CustomButton
                      title={t("next")}
                      variant="blank"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      icon={
                        currentDirection === "ltr" ? nextIcon : previousIcon
                      }
                      iconSize={15}
                      reverseIcon
                    />
                  </div>

                  <div className="pagination-buttons">
                    <span className="pagination-location">
                      {t("totalNumber")}: {totalRecords}
                    </span>
                  </div>
                </section>
              </>
            )}
          </>
        )}

        {/* Edit SubCompany Modal */}
        {subCompanyModal && (
          <Modal
            title={isEditing ? t("editSubCompany") : t("subCompany")}
            onClose={handleCancel}
            footer={
              <>
                {loadingModal ? <Loader /> : null}
                <section className="edit-user-actions">
                  {isEditing ? (
                    <>
                      <CustomButton
                        onClick={handleSaveSubCompany}
                        title={t("confirm")}
                        icon={saveIcon}
                        iconSize={16}
                        disabled={loadingModal}
                      />
                      <CustomButton
                        title={t("cancel")}
                        onClick={handleCancel}
                        variant="blank"
                        icon={closeIcon}
                        iconSize={14}
                      />
                    </>
                  ) : (
                    <>
                      <CustomButton
                        variant="blank"
                        title={t("edit")}
                        onClick={() => setIsEditing(true)}
                        icon={editIcon}
                        iconSize={18}
                      />
                    </>
                  )}
                </section>
              </>
            }
          >
            <div>
              <section>
                <div>
                  {isEditing ? (
                    <>
                      <div className="edit-user-entity">
                        <span>{t("companyName")}: </span>
                        <CustomInput
                          name="companyName"
                          value={subCompanyFields.companyName || ""}
                          onChange={(e) => handleInputChange(e, "companyName")}
                        />
                      </div>
                      <div className="edit-user-entity">
                        <span>{t("companyLocation")}: </span>
                        <CustomInput
                          name="location"
                          value={subCompanyFields.location || ""}
                          onChange={(e) => handleInputChange(e, "location")}
                        />
                      </div>
                      <div className="edit-user-entity">
                        <span>{t("website")}: </span>
                        <CustomInput
                          name="website"
                          value={subCompanyFields.website || ""}
                          onChange={(e) => handleInputChange(e, "website")}
                        />
                      </div>
                      <div className="edit-user-entity">
                        <span>{t("email")}: </span>
                        <CustomInput
                          name="email"
                          value={subCompanyFields.email || ""}
                          onChange={(e) => handleInputChange(e, "email")}
                        />
                      </div>
                      <div className="edit-user-entity">
                        <span>{t("phoneNumber")}: </span>
                        <CustomInput
                          name="phoneNumber"
                          value={subCompanyFields.phoneNumber || ""}
                          onChange={(e) => handleInputChange(e, "phoneNumber")}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="view-user-entity">
                        <p variant="body1">{t("companyName")}:</p>
                        <p variant="body1">{selectedSubCompany?.name}</p>
                      </div>
                      <div className="view-user-entity">
                        <p variant="body1">{t("companyLocation")}:</p>
                        <p variant="body1">{selectedSubCompany?.location}</p>
                      </div>
                      <div className="view-user-entity">
                        <p variant="body1">{t("website")}:</p>
                        <p variant="body1">{selectedSubCompany?.website}</p>
                      </div>
                      <div className="view-user-entity">
                        <p variant="body1">{t("email")}:</p>
                        <p variant="body1">{selectedSubCompany?.email}</p>
                      </div>
                      <div className="view-user-entity">
                        <p variant="body1">{t("phoneNumber")}:</p>
                        <p variant="body1">{selectedSubCompany?.phoneNumber}</p>
                      </div>
                    </>
                  )}
                </div>
              </section>
            </div>
          </Modal>
        )}

        {/* Add SubCompany Modal */}

        {addSubCompanyModal && (
          <Modal
            title={t("createSubCompany")}
            onClose={handleCancelAddSubCompany}
            footer={
              <>
                {loadingModal ? <Loader /> : null}
                <section className="edit-user-actions">
                  <CustomButton
                    title={t("confirm")}
                    onClick={handleCreateSubCompany}
                    icon={saveIcon}
                    iconSize={16}
                    disabled={loadingModal}
                  />
                  <CustomButton
                    title={t("cancel")}
                    onClick={handleCancelAddSubCompany}
                    variant="blank"
                    icon={closeIcon}
                    iconSize={14}
                  />
                </section>
              </>
            }
          >
            <form onSubmit={handleCreateSubCompany}>
              <div>
                {error && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ErrorBox>{errorMessage}</ErrorBox>
                  </div>
                )}
                <div className="edit-user-entity">
                  <span>{t("companyName")}: </span>
                  <CustomInput
                    name="companyName"
                    value={newSubCompanyFields.companyName}
                    onChange={(e) =>
                      handleNewSubCompanyInputChange(e, "companyName")
                    }
                  />
                </div>
                <div className="edit-user-entity">
                  <span>{t("companyLocation")}: </span>
                  <CustomInput
                    name="location"
                    value={newSubCompanyFields.location}
                    onChange={(e) =>
                      handleNewSubCompanyInputChange(e, "location")
                    }
                  />
                </div>
                <div className="edit-user-entity">
                  <span>{t("website")}: </span>
                  <CustomInput
                    name="website"
                    value={newSubCompanyFields.website}
                    onChange={(e) =>
                      handleNewSubCompanyInputChange(e, "website")
                    }
                  />
                </div>
                <div className="edit-user-entity">
                  <span>{t("email")}: </span>
                  <CustomInput
                    name="email"
                    value={newSubCompanyFields.email}
                    onChange={(e) => handleNewSubCompanyInputChange(e, "email")}
                  />
                </div>
                <div className="edit-user-entity">
                  <span>{t("phoneNumber")}: </span>
                  <CustomInput
                    name="phoneNumber"
                    value={newSubCompanyFields.phoneNumber}
                    onChange={(e) =>
                      handleNewSubCompanyInputChange(e, "phoneNumber")
                    }
                  />
                </div>
              </div>
            </form>
          </Modal>
        )}
      </div>
    </div>
  );
}
