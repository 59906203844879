// src/pages/UsersPage.js
import { useState, useEffect } from "react";
import { Container, Typography } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import CustomCheckbox from "../../components/CustomCheckbox";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import useLanguage from "../../utils/useLanguage";
import { useNavigate } from "react-router";
import nextIcon from "../../assets/next_icon.png";
import previousIcon from "../../assets/previous_icon.png";
import deleteIcon from "../../assets/delete_Icon.png";
import saveIcon from "../../assets/save_icon.png";
import closeIcon from "../../assets/close_icon.png";
import editIcon from "../../assets/edit_icon.png";
import addIcon from "../../assets/add_icon.png";
import ErrorBox from "../../components/ErrorBox";
import Dropdown from "../../components/Dropdown";
import Loader from "../../components/Loader";

export default function UsersPage() {
  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [networkError, setNetworkError] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [userFields, setUserFields] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [usersCurrentPage, setUsersCurrentPage] = useState(1);
  const [usersTotalPages, setUsersTotalPages] = useState(1);
  const [usersTotalRecords, setUsersTotalRecords] = useState(0);

  const [loadingModal, setLoadingModal] = useState("");

  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  useEffect(() => {
    getOrganizations();
  }, []);

  const getOrganizations = async () => {
    const token =
      localStorage.getItem("SuperAdminToken") ||
      localStorage.getItem("adminToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/getOrganizations`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.organizations) {
        setOrganizations(response.data.organizations);
      } else {
        console.error("Failed to fetch organizations");
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, [usersCurrentPage, searchTerm]);

  const getUsers = async () => {
    const token =
      localStorage.getItem("SuperAdminToken") ||
      localStorage.getItem("adminToken");
    const userId = parseInt(localStorage.getItem("userId"), 10);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/getUsers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
          },
          params: {
            page: usersCurrentPage,
            limit: 5,
            search: searchTerm,
          },
        }
      );
      console.log("response.data: ", response.data.users);

      if (response.data && response.data.users) {
        setUsers(response.data.users);
        setFilteredUsers(response.data.users);
        setUsersTotalPages(response.data.totalPages);
        setUsersTotalRecords(response.data.totalUsers);
      } else {
        console.error("Failed to get the users");
      }
    } catch (error) {
      setNetworkError(true);
      console.error("Failed to get the users", error);
    }
  };

  const openUserModal = (user) => {
    setSelectedUser(user);
    setUserFields({
      userName: user.username,
      userEmail: user.email,
      userPhoneNumber: user.phoneNumber,
      organizationName:
        user.organization?.organizationName || "No organization",
    });
    setIsEditing(false);
    setUserModal(true);
  };

  const handleInputChange = (e, fieldName) => {
    const value = fieldName === "isBlocked" ? e.target.checked : e.target.value;
    setUserFields({
      ...userFields,
      [fieldName]: value,
    });
  };

  const handleSaveUser = async (user) => {
    try {
      setLoadingModal("editUser");
      const response = await axios.patch(
        `${process.env.REACT_APP_URL}/editUser`,
        {
          id: selectedUser?.id,
          username: userFields.userName,
          email: userFields.userEmail,
          phoneNumber: userFields.userPhoneNumber,
          isBlocked: userFields.isBlocked, // Include isBlocked in the request
        }
      );

      if (response.status === 200) {
        await getUsers();
        setUserModal(false);
      } else {
        console.error("Failed to update user:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setLoadingModal("");
    }
  };

  const handleCancel = () => {
    setRemoveUserModal(false);
    setUserModal(false);
    setSelectedUser(null);
  };

  const openRemoveUserModal = (user) => {
    setSelectedUser(user);
    setRemoveUserModal(true);
  };

  const [showRemoveUserModal, setRemoveUserModal] = useState(false);
  const handleDeleteUser = async () => {
    const token =
      localStorage.getItem("SuperAdminToken") ||
      localStorage.getItem("adminToken");

    try {
      setLoadingModal("deleteUser");
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/deleteUser/${selectedUser.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.message === "User deleted successfully") {
        await getUsers(); // Refresh the user list
        setRemoveUserModal(false);
        setUserModal(false);
      } else {
        console.error("Failed to delete user:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setLoadingModal("");
      setUserModal(false);
    }
  };

  const [message, setMessage] = useState("");
  const [addUserModal, setAddUserModal] = useState(false);
  const [newUserFields, setNewUserFields] = useState({
    userName: "",
    userEmail: "",
    password: "",
    confirmPassword: "",
    isBlocked: false,
  });

  const openAddUserModal = () => {
    setAddUserModal(true);
    setNewUserFields({
      userName: "",
      userEmail: "",
      password: "",
      confirmPassword: "",
      isSuperUser: false,
    });
  };

  const handleNewUserInputChange = (e, fieldName) => {
    const value = fieldName === "isBlocked" ? e.target.checked : e.target.value;
    setNewUserFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();

    try {
      // Determine the language based on currentDirection
      const language = currentDirection === "rtl" ? "ar" : "en";
      setLoadingModal("createUser");
      //  Empty fields validation
      if (
        !newUserFields.userEmail ||
        !newUserFields.userName ||
        !newUserFields.password ||
        !newUserFields.confirmPassword ||
        !newUserFields.phoneNumber ||
        !selectedOrganization
      ) {
        setError(true);
        setErrorMessage(t("emptyInputFields"));
        return;
      }

      // Email validation regex
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (newUserFields.userEmail) {
        if (!emailPattern.test(newUserFields.userEmail)) {
          setError(true);
          setErrorMessage(t("invalidEmail"));
          return;
        }
      }

      // Password match validation
      if (newUserFields.password !== newUserFields.confirmPassword) {
        setError(true);
        setErrorMessage("Passwords do not match");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/createUser`,
        {
          email: newUserFields.userEmail,
          username: newUserFields.userName,
          password: newUserFields.password,
          phoneNumber: newUserFields.phoneNumber,
          isBlocked: newUserFields.isBlocked,
          organization: selectedOrganization,
          language,
        }
      );

      setMessage(response.data.message);
      if (response.data.message === "User created successfully") {
        // Close the modal and refresh the user list
        setError(false);
        setErrorMessage("");
        setAddUserModal(false);
        await getUsers();
      }
      setError(false);
      setErrorMessage("");
      setAddUserModal(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorKey = error.response.data.error;
        console.log("errorKey:", errorKey);
        // Map backend errors to user-friendly messages
        switch (errorKey) {
          case "emailAlreadyInUse":
            setError(true);
            setErrorMessage(t("emailAlreadyInUse"));
            break;
          case "invalidPhoneNumber":
            setError(true);
            setErrorMessage(t("invalidPhoneNumber"));
            break;
          default:
            setError(true);
            setErrorMessage(t("unexpectedError"));
            break;
        }
      } else {
        console.error("Network or other error:", error.message);
        setError(true);
        setErrorMessage(t("networkError"));
      }
    } finally {
      setLoadingModal("");
    }
  };

  const handleCancelAddUser = () => {
    setError(false);
    setErrorMessage("");
    setAddUserModal(false);
  };

  return (
    <div className="e-container">
      <Container
        className="view-users-page-container"
        style={{ direction: currentDirection }}
      >
        <CustomButton
          title={t("back")}
          variant="blank"
          icon={currentDirection === "ltr" ? previousIcon : nextIcon}
          iconSize={14}
          onClick={() => navigate("/controlPanel")}
        />

        <div className="users-title-section">
          <h4 className="title">{t("users", { defaultValue: "users" })}</h4>

          <CustomButton
            title={t("createUser")}
            variant="blank"
            icon={addIcon}
            iconSize={20}
            onClick={openAddUserModal}
          />
        </div>

        <CustomInput
          name="userName"
          value={searchTerm}
          placeholder={searchTerm ? "" : t("search")}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {networkError ? (
          <p color="error">
            {t("networkError", { defaultValue: "Network error occurred." })}
          </p>
        ) : filteredUsers.length === 0 ? (
          <p>{t("noUsers")}</p>
        ) : (
          <ul className="users-list" style={{ direction: currentDirection }}>
            {filteredUsers.map((user) => (
              <li
                className="user-list-item"
                key={user.id}
                onClick={() => openUserModal(user)}
                style={{ cursor: "pointer" }}
              >
                <p className="user-username">
                  {user.username || "No Name Available"}
                </p>
                {user.email && (
                  <Typography variant="subtitle1" color="textSecondary">
                    {user.email}
                  </Typography>
                )}
              </li>
            ))}
          </ul>
        )}

        <section className="pagination">
          <div className="pagination-buttons">
            <CustomButton
              variant="blank"
              title={t("previous")}
              onClick={() => setUsersCurrentPage(usersCurrentPage - 1)}
              disabled={usersCurrentPage === 1}
              icon={currentDirection === "ltr" ? previousIcon : nextIcon}
              iconSize={15}
            />
            <span className="pagination-location">
              {t("page")}: {usersCurrentPage} - {usersTotalPages}
            </span>
            <CustomButton
              title={t("next")}
              variant="blank"
              onClick={() => setUsersCurrentPage(usersCurrentPage + 1)}
              disabled={usersCurrentPage === usersTotalPages}
              icon={currentDirection === "ltr" ? nextIcon : previousIcon}
              iconSize={15}
              reverseIcon
            />
          </div>

          <div className="pagination-buttons">
            <span className="pagination-location">
              {t("totalNumber")}: {usersTotalRecords}
            </span>
          </div>
        </section>

        {userModal && (
          <Modal
            title={isEditing ? t("editUser") : t("user")}
            onClose={handleCancel}
            footer={
              <section>
                {isEditing ? (
                  <>
                    {loadingModal === "editUser" ? <Loader /> : null}
                    <div className="edit-user-actions">
                      <CustomButton
                        title={t("cancel")}
                        onClick={handleCancel}
                        variant="blank"
                        icon={closeIcon}
                        iconSize={14}
                      />
                      <CustomButton
                        title={t("confirm")}
                        onClick={() => handleSaveUser(selectedUser)}
                        icon={saveIcon}
                        iconSize={16}
                        disabled={loadingModal === "editUser"}
                      />
                    </div>
                  </>
                ) : (
                  <div className="edit-user-actions">
                    <CustomButton
                      variant="blank"
                      title={t("edit")}
                      onClick={() => setIsEditing(true)}
                      icon={editIcon}
                      iconSize={18}
                    />
                    <CustomButton
                      title={t("delete")}
                      variant="red"
                      icon={deleteIcon}
                      iconSize={18}
                      onClick={() => openRemoveUserModal(selectedUser)}
                    />
                  </div>
                )}
              </section>
            }
          >
            <div>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("username")}: </span>
                      <CustomInput
                        name="userName"
                        value={userFields.userName || ""}
                        onChange={(e) => handleInputChange(e, "userName")}
                      />
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p>{t("username")}:</p>
                      <p>{selectedUser?.username}</p>
                    </div>
                  )}
                </div>
              </section>
              <section>
                <div className="view-user-entity">
                  <p>{t("email")}:</p>
                  <p>{selectedUser?.email}</p>
                </div>
              </section>
              <section>
                <div>
                  <div className="view-user-entity">
                    <p>{t("organization")}:</p>
                    <p>{selectedUser?.organization?.organizationName}</p>
                  </div>
                </div>
              </section>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("phoneNumber")}: </span>
                      <CustomInput
                        name="userPhoneNumber"
                        value={userFields.userPhoneNumber || ""}
                        onChange={(e) =>
                          handleInputChange(e, "userPhoneNumber")
                        }
                      />
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p>{t("phoneNumber")}:</p>
                      <p>{selectedUser?.phoneNumber}</p>
                    </div>
                  )}
                </div>
              </section>
              <section>
                {isEditing ? (
                  <div className="edit-user-entity">
                    <div className="user-row-section">
                      <CustomCheckbox
                        checked={userFields.isBlocked}
                        // checked={selectedUser?.isBlocked || false}
                        onChange={(e) => handleInputChange(e, "isBlocked")}
                        color="primary"
                      />
                      <label>{t("isBlocked")}</label>
                    </div>
                  </div>
                ) : (
                  <div className="view-user-entity">
                    <p>{t("theUseIsBlocked")}:</p>
                    <p>{selectedUser?.isBlocked ? t("yes") : t("no")}</p>{" "}
                  </div>
                )}
              </section>
            </div>
          </Modal>
        )}

        {showRemoveUserModal && (
          <Modal
            title={t("deleteUser")}
            onClose={() => setRemoveUserModal(false)}
            footer={
              <>
                {loadingModal === "deleteUser" ? <Loader /> : null}
                <section className="delete-user-actions">
                  <CustomButton
                    onClick={handleDeleteUser}
                    title={t("confirm")}
                    icon={saveIcon}
                    iconSize={16}
                    disabled={loadingModal === "deleteUser"}
                  />
                  <CustomButton
                    title={t("cancel")}
                    onClick={() => setRemoveUserModal(false)}
                    variant="blank"
                    icon={closeIcon}
                    iconSize={14}
                  />
                </section>
              </>
            }
          >
            <div className="delete-modal-body">
              <h3>{t("areYouSureYouWantToDeleteThisUser")}</h3>
              <p>{selectedUser?.username || selectedUser?.email}</p>
            </div>
          </Modal>
        )}

        {/* Add User Modal */}
        {addUserModal && (
          <Modal
            title={t("createUser")}
            onClose={handleCancelAddUser}
            footer={
              <>
                {loadingModal === "createUser" ? <Loader /> : null}
                <section className="edit-user-actions">
                  <CustomButton
                    title={t("cancel")}
                    onClick={handleCancelAddUser}
                    variant="blank"
                    icon={closeIcon}
                    iconSize={14}
                  />
                  <CustomButton
                    title={t("confirm")}
                    onClick={handleCreateUser}
                    icon={saveIcon}
                    iconSize={16}
                    disabled={loadingModal === "createUser"}
                  />
                </section>
              </>
            }
          >
            <form onSubmit={handleCreateUser}>
              <div>
                {error && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ErrorBox>{errorMessage}</ErrorBox>
                  </div>
                )}
                <div className="edit-user-entity">
                  <span>{t("username")}: </span>
                  <CustomInput
                    name="userName"
                    value={newUserFields.userName}
                    onChange={(e) => handleNewUserInputChange(e, "userName")}
                  />
                </div>
                <div className="edit-user-entity">
                  <span>{t("email")}: </span>
                  <CustomInput
                    name="userEmail"
                    type="email"
                    value={newUserFields.userEmail}
                    onChange={(e) => handleNewUserInputChange(e, "userEmail")}
                    required
                  />
                </div>
                <div className="edit-user-entity">
                  <span>{t("organization")}: </span>
                  <Dropdown
                    options={organizations?.map((org) => ({
                      ...org,
                      title: org?.organizationName || "Unknown",
                      value: org?.organizationName,
                    }))}
                    selected={selectedOrganization}
                    setSelected={(value) => {
                      setSelectedOrganization(value);
                      console.log("Slected: ", selectedOrganization);
                    }}
                    thick
                    flex
                    required
                  />
                </div>
                <div className="edit-user-entity">
                  <span>{t("phoneNumber")}: </span>
                  <CustomInput
                    name="userName"
                    value={newUserFields.phoneNumber}
                    onChange={(e) => handleNewUserInputChange(e, "phoneNumber")}
                  />
                </div>
                <div className="edit-user-entity">
                  <span>{t("password")}: </span>
                  <CustomInput
                    name="password"
                    type="password"
                    value={newUserFields.password}
                    onChange={(e) => handleNewUserInputChange(e, "password")}
                    required
                  />
                </div>
                <div className="edit-user-entity">
                  <span>{t("confirmPassword")}: </span>
                  <CustomInput
                    name="confirmPassword"
                    type="password"
                    value={newUserFields.confirmPassword}
                    onChange={(e) =>
                      handleNewUserInputChange(e, "confirmPassword")
                    }
                    required
                  />
                </div>
                <div className="edit-user-entity">
                  <div className="user-row-section">
                    <CustomCheckbox
                      checked={newUserFields.isBlocked}
                      onChange={(e) => handleNewUserInputChange(e, "isBlocked")}
                      color="primary"
                    />
                    <label>{t("isBlocked")}</label>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Container>
    </div>
  );
}
