import { el } from "date-fns/locale";
import { createContext, useEffect, useState, useTransition } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../components/Loader";

export const UserAuth = createContext(null);

export default function UserAuthProvider({ children }) {
  const [userData, setUserData] = useState();

  const { t } = useTranslation("general");
  const navigate = useNavigate();

  // console.log("userData Context", userData);

  useEffect(() => {
    const userType = localStorage.getItem("userType");

    const username = localStorage.getItem("username");
    // const supplierId = localStorage.getItem("supplierId");
    // const customerId = localStorage.getItem("customerId");

    const token = localStorage.getItem("token");

    if (!token) navigate("/login");

    if (userType === "organization") {
      const organizationId = localStorage.getItem("organizationId");

      setUserData({
        userType,
        username,
        organizationId,
        // supplierId,
        // customerId,
        token,
      });
    } else if (userType === "orgUser") {
      const userId = localStorage.getItem("userId");
      const organizationId = localStorage.getItem("organizationId");
      const phoneNumber = localStorage.getItem("phoneNumber");

      setUserData({
        userType,
        username,
        userId,
        organizationId,
        // supplierId,
        // customerId,
        phoneNumber,
        token,
      });
    } else if (userType === "user") {
      const email = localStorage.getItem("email");
      const phoneNumber = localStorage.getItem("phoneNumber");
      const userId = localStorage.getItem("userId");
      const username = localStorage.getItem("username");

      setUserData({
        userType,
        username,
        userId,
        email,
        // supplierId,
        // customerId,
        phoneNumber,
        token,
      });
    }
  }, []);

  // console.log("userData", userData);

  if (!userData) {
    return <Loader fullHeight={true} />;
  }

  return (
    <UserAuth.Provider value={{ userData, setUserData }}>
      {children}
    </UserAuth.Provider>
  );
}
