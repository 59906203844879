import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import classes from "./ViewInvoice.module.css";

// * Custom utils
import { toDate } from "../../utils/toDate";
import { toMoney } from "../../utils/toMoney";
import { getCleanedInvoiceNumber } from "../../utils/getCleanedInvoiceNumber";
import Header from "../../components/Header";
import { DataContext } from "../../utils/DataContext";

export default function PrintInvoices() {
  const { t } = useTranslation("general");
  const [state, setState] = useState([]);
  const { selectedSupplier } = useContext(DataContext);

  useEffect(() => {
    const storedRows = sessionStorage.getItem("selectedRows");
    if (storedRows) {
      setState(JSON.parse(storedRows));
    }

    const handleBeforePrint = () => {
      document.title = "Company name";
    };

    const handleAfterPrint = () => {
      document.title = "Ehsibly";
    };

    window.print();

    window.addEventListener("beforeprint", handleBeforePrint);
    window.addEventListener("afterprint", handleAfterPrint);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);

  return (
    <main className={classes.viewInvoicePage}>
      <div className="header-container ">
        {/* <Header /> */}
      </div>
      <div className={classes.container}>
        {state?.map((data) => (
          <div style={{ minHeight: "100vh" }}>
            <section className={`${classes.section} ${classes.invoiceNumber}`}>
              <div className={classes.col}>
                <span>{t("Invoice Number")}: </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {getCleanedInvoiceNumber(data?.invoiceNumber)}
                </span>
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.col}>
                <span>{t("supplier")}: </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {data?.Supplier?.name}
                </span>
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.col}>
                <span>{t("Date")}: </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toDate(data?.date)}
                </span>
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.col}>
                <span>{t("Amount")}: </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toMoney(data?.amount)}
                </span>
              </div>
              <div className={classes.col}>
                <span>{t("Currency")}: </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {data?.amountCurrency}
                </span>
              </div>
              <div className={classes.col}>
                <span>{t("Bank name")}: </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {data?.bankName}
                </span>
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.col}>
                <span>{t("amountIn")}: </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toMoney(data?.amountInSupCur)} {selectedSupplier?.currency}
                </span>
              </div>

              <div className={classes.col}>
                <span>{t("balanceBeforeUpdate")}: </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toMoney(data?.balanceBeforeUpdate)}{" "}
                  {selectedSupplier?.currency}
                </span>
              </div>
              <div className={classes.col}>
                <span>{t("balanceAfterUpdate")}: </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toMoney(data?.balanceAfterUpdate)}{" "}
                  {selectedSupplier?.currency}
                </span>
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.col}>
                <span>{t("SWIFT")}: </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {data?.swift}
                </span>
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.col}>
                <span>{t("Notes")}: </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {data?.notes}
                </span>
              </div>
            </section>
            {/* <PDFUploader
              ref={pdfUploaderRef}
              invoiceNumber={data?.invoiceNumber}
              invoiceDocs={invoiceDocs}
              setInvoiceDocs={setInvoiceDocs}
              editMode={editMode}
              documents={data.documents}
            /> */}
          </div>
        ))}
      </div>
    </main>
  );
}
