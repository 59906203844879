// src/utils/DataContext.js
import { useState, useEffect, createContext, useRef, useContext } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { UserAuth } from "./AuthContext";

export const DataContext = createContext(null);

export default function DataProvider({ children }) {
  // * get userData
  const { userData } = useContext(UserAuth);
  const { token, userType, userId, supplierId, customerId, organizationId } =
    userData;

  const { t } = useTranslation("general");

  const [searchError, setSearchError] = useState(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState("");

  const currencies = [
    { title: "USD", value: "USD" },
    { title: "IQD", value: "IQD" },
    { title: "EURO", value: "EURO" },
    { title: "RMB", value: "RMB" },
    { title: "TRY", value: "TRY" },
  ];

  // * Main APP data
  const [invoices, setInvoices] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [subCompanies, setSubCompanies] = useState([]);

  const [selectedInvoicesStatus, setSelectedInvoicesStatus] = useState({
    title: "Active",
    value: "active",
  });

  const [selectedTitle, setSelectedTitle] = useState({
    title: t("Invoice Number"),
    value: "invoiceNumber",
  });
  const [searchQuery, setSearchQuery] = useState("");

  const [searchTerm, setSearchTerm] = useState("");

  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedSubCompany, setSelectedSubCompany] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  // * Pagination for Invoices
  const [invoicesCurrentPage, setInvoicesCurrentPage] = useState(1);
  const [invoicesTotalPages, setInvoicesTotalPages] = useState(1);
  const [invoicesTotalRecords, setInviocesTotalRecords] = useState(1);
  const [networkError, setNetworkError] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState({
    isPending: false,
    isSuccess: false,
    isFailed: false,
  });

  const [shippedContainers, setShippedContainers] = useState([]);

  const [subCompaniesCurrentPage, setSubCompaniesCurrentPage] = useState(1);
  // * Pagination for the ShippedContainers
  const [shippedContainersTotalPages, setShippedContainersTotalPages] =
    useState(1);
  const [shippedContainersCount, setShippedContainersCount] = useState(1);
  const [shippedContainersCurrentPage, setShippedContainersCurrentPage] =
    useState(1);

  const [currentBalance, setCurrentBalance] = useState(null);

  const [supplierModal, setSupplierModal] = useState(false);
  const [supplierFields, setSupplierFields] = useState({
    supplierName: "",
    supplierCompany: "",
    supplierLocation: "",
  });

  const [supplierCurrency, setSupplierCurrency] = useState({
    title: "USD",
    value: "USD",
  });

  const [shippedContainersBalance, setShippedContainersBalance] = useState({
    withCustomer: 0,
    withSupplier: 0,
  });

  // useEffect(() => {
  //   const getCurrentBalance = async () => await fetchCurrentBalance();
  //   if (selectedSupplier) {
  //     getCurrentBalance();
  //   }
  // }, [selectedSupplier.id]);

  // useEffect(() => {
  //   setLoading(false);
  // }, []);

  useEffect(() => {
    const getNewInvoicesPage = async () => {
      if (selectedInvoicesStatus.value === "active") {
        if (searchQuery) {
          await handleSearch();
        } else {
          await fetchInvoices();
        }
      } else if (selectedInvoicesStatus.value === "archived") {
        await fetchArchivedInvoices(selectedSupplier?.id);
      } else {
        // console.log("Please select valid type of invoices");
      }
    };

    if (selectedSupplier?.id) {
      getNewInvoicesPage();
    }
  }, [invoicesCurrentPage]);

  const fetchInvoices = async (supplierId) => {
    try {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");
      const organizationId = localStorage.getItem("organizationId");

      setLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/getInvoices`,
        {
          params: { page: invoicesCurrentPage, limit: 10 },
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
            "organization-Id": organizationId,
            "Supplier-Id": supplierId || selectedSupplier?.id,
          },
        }
      );

      if (response.status === 200) {
        console.log("STATUS", 200);
        setInvoices(response.data?.invoices);
        setInvoicesTotalPages(response.data?.totalPages);
        setInviocesTotalRecords(response.data?.totalInvoices);
      }

      const resCurrentSupplier = response?.data?.currentSupplier;

      if (resCurrentSupplier) {
        setSelectedSupplier(resCurrentSupplier);
      }
      return response.data;
    } catch (error) {
      // setNetworkError(true);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveSupplier = async () => {
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);
    const organizationId = localStorage.getItem("organizationId");

    if (!supplierFields?.supplierName) {
      setError("noSupplierName");
      return;
    }

    try {
      const newSupplier = await axios.post(
        `${process.env.REACT_APP_URL}/createSupplier`,
        {
          userId: userId,
          organizationId: organizationId || null,
          ...supplierFields,
          supplierCurrency: supplierCurrency.value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!newSupplier) {
        console.error("Failed to save the supplier", error);
      }
      setSupplierModal(false);
      setSupplierFields({
        supplierName: "",
        supplierCompany: "",
        supplierLocation: "",
      });
      setSupplierCurrency({
        title: "USD",
        value: "USD",
      });

      const newSupplierData = newSupplier?.data?.newSupplier;
      setSuppliers((prev) => [newSupplierData, ...prev]);

      if (newSupplier?.data?.currentSupplierId) {
        setSelectedSupplier(newSupplierData);
      }
    } catch (error) {
      setNetworkError(true);
      console.error("Failed to save the supplier", error);
    }
  };

  const handleSelectSupplier = async (supplier, page = "invoices") => {
    setSelectedSupplier(supplier);
    if (page === "invoices") {
      await fetchInvoices(supplier?.id);
    } else if (page === "shippedContainers") {
      await fetchShippedContainers(supplier?.id, selectedCustomer?.id);
    }
    setSearchQuery("");
    // localStorage.setItem("supplierId", supplier?.id);
  };

  const handleSearch = async () => {
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);

    if (selectedSupplier?.id) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/searchInvoices`,
          {
            params: {
              query: searchQuery,
              title: selectedTitle.value,
              dateToSearchFor:
                selectedTitle.value === "date" ? selectedDate : "",
              page: invoicesCurrentPage,
              invoicesStatus: selectedInvoicesStatus.value,
              limit: 10,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              "User-Id": userId,
              "Supplier-Id": selectedSupplier?.id,
            },
          }
        );

        if (!response) {
          console.error("Failed to get the search results");
        }

        if (response?.data?.searchResults.length) {
          setSearchError(false);
        }

        console.log(response?.data?.searchResults);
        setInvoices(response?.data?.searchResults || []);
        setInvoicesCurrentPage(response?.data?.currentPage);
        setInviocesTotalRecords(response?.data?.totalInvoices);
        setInvoicesTotalPages(response?.data?.totalPages);
        setCurrentBalance(response?.data?.currentBalance);
      } catch (error) {
        setInvoices([]);
        setSearchError(true);
        setSearchErrorMessage(error.response.data.error);
        console.error("Error searching invoices:", error);
      }
    }
  };

  const getSuppliers = async (isDropDown = false) => {
    const token = localStorage.getItem("token");
    const supplierId = localStorage.getItem("supplierId");
    // Ensure userId is stored as a number
    const userId = parseInt(localStorage.getItem("userId"), 10);
    const organizationId =
      parseInt(localStorage.getItem("organizationId"), 10) || null;

    // * Enable the below line
    // if (suppliers.length) return;

    try {
      if (!isDropDown) {
        setPageLoading((prev) => ({ ...prev, isPending: true }));
      }

      const suppliers = await axios.get(
        `${process.env.REACT_APP_URL}/getAllSuppliers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
            "organization-Id": organizationId,
          },
        }
      );

      if (!suppliers) {
        console.error("Failed to get the suppliers", error);
      }

      const existingSupliers = suppliers?.data?.data;

      setSuppliers(existingSupliers);

      existingSupliers?.map((supplier) => {
        if (`${supplier.id}` === supplierId) {
          setSelectedSupplier(supplier);
        }
      });

      if (supplierId === "All") {
        setSelectedSupplier({ id: "All", name: "All" });
      }

      if (suppliers?.status === 200) {
        setPageLoading((prev) => ({
          ...prev,
          isPending: false,
          isSuccess: true,
        }));
      } else {
        setPageLoading((prev) => ({
          ...prev,
          isPending: false,
          isFailed: true,
        }));
      }

      return suppliers;
    } catch (error) {
      setNetworkError(true);
      console.error("Failed to get the suppliers", error);
      setPageLoading((prev) => ({
        ...prev,
        isPending: false,
        isFailed: true,
      }));
    }
  };

  const getCustomers = async (page, limit, isDropDown = false) => {
    try {
      if (!isDropDown) {
        setPageLoading((prev) => ({ ...prev, isPending: true }));
      }
      const customers = await axios.get(
        `${process.env.REACT_APP_URL}/getCustomers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
            "organization-Id": organizationId,
          },
          params: {
            page: page ?? 1,
            limit: limit ?? 5,
            search: searchTerm,
          },
        }
      );

      if (!customers) {
        console.error("Failed to get the cusotmers", error);
      }

      const existingCustomers = customers?.data?.customers;

      setCustomers(existingCustomers);

      existingCustomers?.map((customer) => {
        if (`${customer.id}` === customerId) {
          setSelectedCustomer(customer);
        }
      });

      if (customerId === "All") {
        setSelectedCustomer({ id: "All", name: "All" });
      }

      if (customers?.status === 200) {
        setPageLoading((prev) => ({
          ...prev,
          isPending: false,
          isSuccess: true,
        }));
      } else {
        setPageLoading((prev) => ({
          ...prev,
          isPending: false,
          isFailed: true,
        }));
      }

      return customers;
    } catch (error) {
      setNetworkError(true);
      console.error("Failed to get the Customers", error);
      setPageLoading((prev) => ({
        ...prev,
        isPending: false,
        isFailed: true,
      }));
    }
  };

  const getSubCompanies = async (page, limit, isDropDown = false) => {
    try {
      if (isNaN(userData?.organizationId)) {
        return;
      }

      if (!isDropDown) {
        setPageLoading((prev) => ({ ...prev, isPending: true }));
      }

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/getSubCompanies`,
        {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
            "organization-Id": parseInt(userData?.organizationId),
          },
          params: {
            page: page ?? subCompaniesCurrentPage,
            limit: limit ?? 5,
            search: searchTerm,
          },
        }
      );

      if (response?.status === 200) {
        setPageLoading((prev) => ({
          ...prev,
          isPending: false,
          isSuccess: true,
        }));
      } else {
        setPageLoading((prev) => ({
          ...prev,
          isPending: false,
          isFailed: true,
        }));
      }

      if (response?.data?.subCompanies) {
        setSubCompanies(response?.data?.subCompanies);
        // return response?.data;
        // setFilteredSubCompanies(response?.data?.subCompanies);
        // setTotalPages(response.data.totalPages); // Set total pages from the response
        // setTotalRecords(response.data.totalSubCompanies); // Set total records from the response
      } else {
        console.error("Failed to get the SubCompanies");
      }
    } catch (error) {
      setNetworkError(true);
      console.error("Failed to get the SubCompanies", error);
      setPageLoading((prev) => ({
        ...prev,
        isPending: false,
        isFailed: true,
      }));
    }
  };

  const fetchArchivedInvoices = async (supplierId) => {
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/getArchivedInvoices`,
        {
          params: { page: invoicesCurrentPage, limit: 10 },

          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
            "Supplier-Id": supplierId,
          },
        }
      );
      if (res.data) {
        setInvoices(res?.data?.invoices);
        setInvoicesTotalPages(res.data?.totalPages);
        setInviocesTotalRecords(res.data?.totalInvoices);
      }
    } catch (error) {
      setNetworkError(true);
      console.error("Falied to get the archived invoices", error);
    }
  };

  const fetchCurrentBalance = async () => {
    console.log("selectedSupplier?.id", selectedSupplier?.id);
    if (selectedSupplier?.id && selectedSupplier?.id !== "All") {
      try {
        setPageLoading((prev) => ({ ...prev, isPending: true }));
        const token = localStorage.getItem("token");
        const userId = parseInt(localStorage.getItem("userId"));
        const organizationId = localStorage.getItem("organizationId");

        const response = await axios.get(
          `${process.env.REACT_APP_URL}/getCurrentBalance`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "User-Id": userId,
              "supplier-Id": selectedSupplier?.id,
              "organization-Id": organizationId,
            },
          }
        );

        setCurrentBalance(response?.data);

        if (response?.status === 200) {
          setPageLoading((prev) => ({
            ...prev,
            isPending: false,
            isSuccess: true,
          }));
        } else {
          setPageLoading((prev) => ({
            ...prev,
            isPending: false,
            isSuccess: false,
            isFailed: true,
          }));
        }
      } catch (error) {
        setNetworkError(true);
        setLoading(false);
        console.error("Error fetching data:", error);
        setPageLoading((prev) => ({
          ...prev,
          isPending: false,
          isSuccess: false,
          isFailed: true,
        }));
      }
    }
  };

  const handleShowSupplierModal = () => {
    setSupplierModal(true);
  };

  const handleSelectCustomer = async (customer) => {
    setSelectedCustomer(customer);
    await fetchShippedContainers(selectedSupplier?.id, customer?.id);
    // localStorage.setItem("customerId", customer?.id);
  };

  const handleSelectSubCompany = (subCompany) => {
    // console.log("subCompany", subCompany);
    setSelectedSubCompany(subCompany);
  };

  const fetchShippedContainers = async (
    supplierId,
    customerId,
    searchType,
    searchQuery
  ) => {
    const localSupplier = localStorage.getItem("supplierId");
    const localCustomer = localStorage.getItem("customerId");

    try {
      // setLoading(true);
      setPageLoading({ isPending: true, isSuccess: false, isFailed: false });

      // if (supplierId && supplierId !== "undefined") {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/getShippedContainers`,
        {
          params: {
            page: shippedContainersCurrentPage,
            limit: 10,
            query: searchQuery,
            type: searchType,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Supplier-Id": supplierId || localSupplier,
            "customer-Id": customerId || localCustomer,
            "User-Id": userId,
            "Organization-Id": organizationId,
          },
        }
      );

      if (response.data) {
        setShippedContainers(response.data?.shippedContainers);
        setShippedContainersTotalPages(response.data?.totalPages);
        setShippedContainersCount(response.data?.totalShippedContainers);
        setSelectedSupplier(response?.data?.currentSupplier);
        setSelectedCustomer(response?.data?.currentCustomer);
      }

      if (response?.status === 200) {
        setPageLoading({ isPending: false, isSuccess: true, isFailed: false });
      } else {
        setPageLoading({ isPending: false, isSuccess: true, isFailed: false });
      }

      return response.data;
      // }
    } catch (error) {
      setNetworkError(true);
      console.error("Error fetching data:", error);
      setPageLoading({ isPending: false, isSuccess: false, isFailed: true });
    } finally {
      // setLoa]]ding(false);
    }
  };

  const fetchShippedContainersBalance = async () => {
    try {
      const balance = await axios.get(
        `${process.env.REACT_APP_URL}/getShippedContainerBalance`,
        {
          params: {
            customerId:
              selectedCustomer?.id !== "All" ? selectedCustomer?.id : "",
            supplierId:
              selectedSupplier?.id !== "All" ? selectedSupplier?.id : "",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log("balance", balance);
    } catch {
      setNetworkError(true);
      console.error("Error fetching data:", error);
    }
  };

  const fetchArchivedShippedContainers = async (supplierId) => {
    // const token = localStorage.getItem("token");
    // const userId = parseInt(localStorage.getItem("userId"), 10);
    const { token, userId } = userData;

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/getArchivedShippedContainers`,
        {
          params: { page: invoicesCurrentPage, limit: 10 },

          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
            "Supplier-Id": supplierId,
          },
        }
      );
      if (res.data) {
        setShippedContainers(res?.data?.archivedShippedContainers);
        // setInvoices(res?.data?.invoices);
        setInvoicesTotalPages(res.data?.totalPages);
        setInviocesTotalRecords(res.data?.totalShippedContainers);
      }
    } catch (error) {
      setNetworkError(true);
      console.error("Falied to get the archived invoices", error);
    }
  };

  return (
    <DataContext.Provider
      value={{
        suppliers,
        setSuppliers,
        getSuppliers,
        customers,
        setCustomers,
        getCustomers,
        supplierModal,
        setSupplierModal,
        selectedSupplier,
        setSelectedSupplier,
        selectedCustomer,
        selectedSubCompany,
        setSelectedSubCompany,
        searchTerm,
        setSearchTerm,
        setSelectedCustomer,
        handleSelectSupplier,
        handleSelectCustomer,
        handleShowSupplierModal,
        error,
        setError,
        networkError,
        setNetworkError,
        loading,
        setLoading,
        pageLoading,
        setPageLoading,
        data: invoices,
        setData: setInvoices,
        shippedContainers,
        setShippedContainers,
        subCompanies,
        setSubCompanies,
        getSubCompanies,
        currentBalance,
        setCurrentBalance,
        handleSaveSupplier,
        supplierFields,
        supplierCurrency,
        setSupplierCurrency,
        setSupplierFields,
        invoicesCurrentPage,
        setInvoicesCurrentPage,
        invoicesTotalPages,
        invoicesTotalRecords,
        fetchInvoices,
        fetchArchivedInvoices,
        fetchShippedContainers,
        handleSearch,
        selectedDate,
        setSelectedDate,
        setInvoicesTotalPages,
        setInviocesTotalRecords,
        selectedInvoicesStatus,
        setSelectedInvoicesStatus,
        searchQuery,
        setSearchQuery,
        selectedTitle,
        setSelectedTitle,
        currencies,
        searchError,
        searchErrorMessage,
        fetchCurrentBalance,
        shippedContainersCount,
        setShippedContainersCount,
        shippedContainersCurrentPage,
        setShippedContainersCurrentPage,
        shippedContainersTotalPages,
        handleSelectSubCompany,
        fetchArchivedShippedContainers,
        shippedContainersBalance,
        setShippedContainersBalance,
        fetchShippedContainersBalance,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
