// src/pages/ControlPanel.js
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { useTranslation } from "react-i18next";
import useLanguage from "../../utils/useLanguage";

export default function ControlPanel() {
  const superAdminAuth = localStorage.getItem("SuperAdminToken");
  const navigate = useNavigate();
  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();

  const viewUsers = () => {
    navigate("/users");
  };

  const viewAdmins = () => {
    navigate("/admins");
  };

  return (
    <div className="control-panel-page" style={{ direction: currentDirection }}>
      <div className="control-panel-container">
        <div className="user-section">
          <div className="user">
            <span className="user-text">{`${t("admin")}:`}</span>
            <span className="user-text">{`${localStorage.getItem(
              "username"
            )}`}</span>
          </div>
        </div>
        <h1>{t("controlPanel")}</h1>
        <div className="control-panel-actions">
          <CustomButton
            title={t("viewUsers")}
            variant="blank"
            onClick={viewUsers}
          />
          {superAdminAuth && (
            <CustomButton
              title={t("viewAdmins")}
              variant="blank"
              onClick={viewAdmins}
            />
          )}
        </div>
      </div>
    </div>
  );
}
