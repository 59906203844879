// src/pages/user-pages/InvoicesManagement.js
import { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import "../InvoicesManagement.scss";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CustomCheckbox from "../../components/CustomCheckbox";
import ChartModal from "../../components/ChartModal";
import CustomInput from "../../components/CustomInput";
import Dropdown from "../../components/Dropdown";
import { toMoney } from "../../utils/toMoney";
import { useTranslation } from "react-i18next";
import useLanguage from "../../utils/useLanguage";
import CustomButton from "../../components/CustomButton";
import containersIcon from "../../assets/container.png";
import CustomLink from "../../components/CustomLink";
import { getCleanedInvoiceNumber } from "../../utils/getCleanedInvoiceNumber";
import InformationBox from "../../components/InformationBox";
import Modal from "../../components/Modal";
import classes from "../../components/Modal.module.css";
import Header from "../../components/Header";

import supplierIcon from "../../assets/supplier_icon.png";
import previousIcon from "../../assets/previous_icon.png";
import nextIcon from "../../assets/next_icon.png";
import addIconLight from "../../assets/add_icon_light.png";
import searchIcon from "../../assets/search_icon.png";
import saveIcon from "../../assets/save_icon.png";
import closeIcon from "../../assets/close_icon.png";
import printIcon from "../../assets/printer_icon.png";

import UserSection from "../../components/UserSection";
import { DataContext } from "../../utils/DataContext";
import PDFUploader from "../../components/PDFUploader";
import { sanitizeNumber } from "../../utils/sanitizeNumber";
import { UserAuth } from "../../utils/AuthContext";
import NetworkError from "../../components/NetworkError";
import BackToDashboard from "../../components/BackToDashboard";
import Loader from "../../components/Loader";

const InvoicesManagement = () => {
  const rows = [
    {
      title: "Invoice Number",
      value: "invoiceNumber",
    },
    {
      title: "Amount",
      value: "amount",
    },
    {
      title: "Currency",
      value: "amountCurrency",
    },
    {
      title: "Bank name",
      value: "bankName",
    },
    {
      title: "amountIn",
      value: "amountInSupCur",
    },
    {
      title: "Date",
      value: "date",
    },
    {
      title: "Notes",
      value: "notes",
    },
  ];

  const [newRow, setNewRow] = useState({
    invoiceNumber: "",
    amount: "",
    amountCurrency: "USD",
    currencyRate: "",
    amountInSupCur: "",
    bankName: "",
    balance: 0,
    date: new Date(),
    notes: "",
    swift: "",
  });

  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();

  const [selectedCurrency, setSelectedCurrency] = useState({
    title: "USD",
    id: "USD",
  });

  const [showInputFields, setShowInputFields] = useState(false);
  const [showAddInvoiceButton, setShowAddInvoiceButton] = useState(true);
  const [newRecord, setNewRecord] = useState({
    invoiceNumber: "",
    amount: "",
    amountCurrency: "USD",
    currencyRate: "",
    amountInSupCur: "",
    bankName: "",
    balance: 0,
    date: new Date(),
    notes: "",
    swift: "",
  });
  const [showAddRowButton, setShowAddRowButton] = useState(true);
  const [invoiceDocs, setInvoiceDocs] = useState([]);
  const pdfUploaderRef = useRef(null);
  const [loadingModal, setLoadingModal] = useState(false);

  const handleChildUploadDocs = (invoiceId) => {
    // Implement the logic to call the child's handleUploadDocs function
    if (pdfUploaderRef.current) {
      pdfUploaderRef.current.handleUploadDocs(invoiceId);
    }
  };

  const { userData } = useContext(UserAuth);

  const {
    suppliers,
    getSuppliers,
    supplierModal,
    setSupplierModal,
    selectedSupplier,
    error,
    setError,
    loading,
    setLoading,
    pageLoading,
    data,
    setData,
    currentBalance,
    setCurrentBalance,
    handleSelectSupplier,
    handleSaveSupplier,
    supplierFields,
    setSupplierFields,
    supplierCurrency,
    setSupplierCurrency,
    invoicesCurrentPage,
    setInvoicesCurrentPage,
    invoicesTotalPages,
    invoicesTotalRecords,
    setInviocesTotalRecords,
    fetchInvoices,
    fetchArchivedInvoices,
    handleSearch,
    selectedDate,
    setSelectedDate,
    selectedInvoicesStatus,
    setSelectedInvoicesStatus,
    searchQuery,
    setSearchQuery,
    selectedTitle,
    setSelectedTitle,
    currencies,
    networkError,
    setNetworkError,
    searchError,
    searchErrorMessage,
    handleShowSupplierModal,
    fetchCurrentBalance,
  } = useContext(DataContext);

  const handleAddSupplier = async () => {
    setLoadingModal(true);
    await handleSaveSupplier();
    setLoadingModal(false);
  };

  const [selectAll, setSelectAll] = useState(false);
  const [chartModalOpen, setChartModalOpen] = useState(false);
  const [tempCurrentBalance, setTempCurrentBalance] = useState({
    value: 0,
    containerId: 0,
  });

  const [dublicatedInvoiceNumber, setDublicatedInvoiceNumber] = useState("");

  const balanceStyle =
    currentBalance?.value >= 0 ? "positive-value" : "negative-value";

  const handleShowChart = () => {
    setChartModalOpen(true);
  };

  const handleCloseChart = () => {
    setChartModalOpen(false);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    // Update the selected status for all rows in the data array
    setData((prevData) => {
      return prevData.map((row) => {
        return { ...row, selected: !selectAll };
      });
    });
  };

  // Prevent entering numbers in input field
  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
      "Enter",
    ];
    const isNumberOrSymbol = /\d|\,|\./.test(e.key);

    if (!isNumberOrSymbol && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleInputChange = (e, modal) => {
    const { name, value } = e.target;
    setError("");

    if (modal === "supplier") {
      setSupplierFields((prev) => ({ ...prev, [name]: value }));
    } else {
      setNewRecord((prevRec) => {
        let updatedRecord = { ...prevRec, [name]: value };

        if (name === "amountInSupCur") {
          if (value) {
            setTempCurrentBalance((prev) => ({
              ...prev,
              value: currentBalance.value + parseFloat(sanitizeNumber(value)),
            }));
          } else {
            setTempCurrentBalance((prev) => ({
              ...prev,
              value: currentBalance.value,
            }));
          }
        }

        return updatedRecord;
      });

      // ! Old code
      setNewRow((prevRow) => {
        let updatedRow = { ...prevRow, [name]: value };

        if (name === "amountInSupCur") {
          if (value) {
            setTempCurrentBalance((prev) => ({
              ...prev,
              value: currentBalance.value + parseFloat(value),
            }));
          } else {
            setTempCurrentBalance((prev) => ({
              ...prev,
              value: currentBalance.value,
            }));
          }
        }

        return updatedRow;
      });
    }
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    setNewRow((prevRow) => ({ ...prevRow, date: new Date(date) }));
    setNewRecord((prevRecord) => ({ ...prevRecord, date: new Date(date) }));
  };

  // const isInvoiceNumberUnique = (invoiceNumber) => {
  //   return !data.some((row) => row.invoiceNumber === invoiceNumber);
  // };

  const handleAddRow = async () => {
    if (!selectedSupplier?.id) {
      setError("emptyInputField");
      return;
    }

    if (!newRow.invoiceNumber.trim()) {
      setError(t("emptyInputField"));
      return;
    }

    if (!newRow.amount.trim()) {
      setError(t("emptyInputField"));
      return;
    }

    if (!newRow.amountInSupCur.trim()) {
      setError(t("emptyInputField"));
      return;
    }

    // if (!isInvoiceNumberUnique(newRow.invoiceNumber)) {
    //   setDublicatedInvoiceNumber(t("dublicatedInvoiceNumber"));
    //   return;
    // }

    try {
      const token = userData?.token;
      const userId = parseInt(userData.userId, 10);

      if (!userId) {
        console.error("User ID not found in localStorage");
        return;
      }

      setLoadingModal(true);
      const newInvoice = await axios.post(
        `${process.env.REACT_APP_URL}/createInvoice`,
        {
          ...newRow,
          amount: sanitizeNumber(newRow?.amount),
          amountInSupCur: sanitizeNumber(newRow?.amountInSupCur),
          balanceAfterUpdate:
            currentBalance?.value +
            parseFloat(sanitizeNumber(newRow.amountInSupCur)),
          balanceBeforeUpdate: currentBalance.value,
          userId,
          supplierId: selectedSupplier?.id,
          amountCurrency: selectedCurrency?.title,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
          },
        }
      );

      // ! IMPORANTT
      // fetchData(selectedSupplier?.id);
      const newInvoiceId = newInvoice?.data?.id;
      const uploadDocuments = handleChildUploadDocs(newInvoiceId);

      setNewRecord({
        invoiceNumber: "",
        companyName: "",
        customerName: "",
        containerNumber: "",
        amount: "",
        amountCurrency: "USD",
        bankName: "",
        received: "",
        left: "",
        swift: "",
        date: new Date(),
        notes: "",
      });
      setNewRow({
        invoiceNumber: "",
        companyName: "",
        customerName: "",
        containerNumber: "",
        amount: "",
        amountCurrency: "USD",
        bankName: "",
        received: "",
        left: "",
        swift: "",
        date: new Date(),
        notes: "",
      });
      setError("");
      setShowInputFields(false);
      setCurrentBalance(tempCurrentBalance);
      setData((prev) => [newInvoice.data, ...prev]);
      setInviocesTotalRecords((prev) => prev + 1);
    } catch (error) {
      console.error("Error adding new invoice:", error);
      setError("addInvoiceFailed");
      setNetworkError(true);
    } finally {
      setLoadingModal(false);
    }
  };

  const handleShowInputFields = () => {
    setShowInputFields(true);
  };

  const handleCancel = (modal) => {
    if (modal === "supplier") {
      setSupplierModal(false);
    } else {
      setShowAddRowButton(true);
      setShowInputFields(false);
      setError("");
      setInvoiceDocs([]);
      setDublicatedInvoiceNumber("");
      setNewRecord({
        invoiceNumber: "",
        companyName: "",
        customerName: "",
        amount: "",
        amountCurrency: "USD",
        bankName: "",
        received: "",
        left: "",
        swift: "",
        date: new Date(),
        notes: "",
      });
      setNewRow({
        invoiceNumber: "",
        companyName: "",
        customerName: "",
        amount: "",
        amountCurrency: "USD",
        bankName: "",
        received: "",
        left: "",
        swift: "",
        date: new Date(),
        notes: "",
      });
    }
  };

  const [saerchByDate, setSaerchByDate] = useState(false);
  const handleSearchType = (type) => {
    setSelectedTitle(type);
    setSaerchByDate(false);
    if (type.value === "date") {
      setSaerchByDate(true);
    }
  };

  const handlePrint = () => {
    const selectedRows = data.filter((row) => row.selected);
    // console.log("Selected Row: ", selectedRows);
    if (selectedRows.length) {
      setError("");
      sessionStorage.setItem("selectedRows", JSON.stringify(selectedRows));
      window.open("/print", "_blank");
    } else {
      setError("noInvoiceToPrint");
    }
  };

  const handleChangeStatus = (status) => {
    const { value } = status;
    setSelectedInvoicesStatus(status);
    setInvoicesCurrentPage(1);
    setSearchQuery("");

    if (value === "active") {
      setShowAddInvoiceButton(true);
      fetchInvoices(selectedSupplier?.id);
    } else if (value === "archived") {
      setShowAddInvoiceButton(false);
      fetchArchivedInvoices(selectedSupplier?.id);
    }
  };

  // Use effect to reset status to "Active" on component mount
  useEffect(() => {
    // if (!suppliers.length) {
    //   const getAllSuppliers = async () => await getSuppliers();
    //   getAllSuppliers();
    // }
    // setLoading(false);
    fetchInvoices();
  }, []);

  // * GET current balance for the selected Supplier
  useEffect(() => {
    if (selectedSupplier) {
      const getCurrentBalance = async () => await fetchCurrentBalance();

      getCurrentBalance();
    }
    // handleChangeStatus({ title: "Active", value: "active" });
  }, [selectedSupplier?.id]);

  // if (networkError) {
  //   return (
  //     <main className="main">
  //       <div className="invoice-table-container">
  //         <NetworkError>{t("networkError")}</NetworkError>
  //       </div>
  //     </main>
  //   );
  // }

  return (
    <>
      <main className="e-container">
        <div>
          <BackToDashboard
            title={t("back")}
            variant="blank"
            icon={currentDirection === "ltr" ? previousIcon : nextIcon}
            iconSize={14}
          />
        </div>
        <div className="invoice-table-container">
          {/* <Header /> */}

          <UserSection handleShowChart={handleShowChart} />

          <div className="user-section">
            <div className="page-title">{t("invoicesPage")}</div>
            <div className="o-row responsive-box">
              <div className="navigation-button-container">
                <CustomButton
                  elementType={Link}
                  title={t("containersPage")}
                  to="/orgContainerPrices"
                  icon={containersIcon}
                  iconSize="30"
                  onClick={() => setSupplierModal(false)}
                />
              </div>
            </div>
          </div>

          {selectedSupplier.id ? (
            <>
              <div className="search-bar">
                <section className="col">
                  <label>{t("search")}: </label>

                  {saerchByDate ? (
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => {
                        setSelectedDate(date.toLocaleDateString("en-CA"));
                      }}
                      dateFormat="yyyy-MM-dd"
                      placeholderText={t("searchByDate")}
                    />
                  ) : (
                    <CustomInput
                      placeholder={t("search")}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      thick
                    />
                  )}
                </section>

                <section className="col title-col">
                  <label>{t("searchTitle")}: </label>
                  <Dropdown
                    options={rows.map((row) => ({
                      ...row,
                      title:
                        row.title === "amountIn"
                          ? t("amountInSupplierCurrency")
                          : row.title,
                    }))}
                    selected={selectedTitle}
                    setSelected={handleSearchType}
                    thick
                  />
                </section>
                <section className="col button-col">
                  <CustomButton
                    title={t("search")}
                    onClick={handleSearch}
                    icon={searchIcon}
                    iconSize={15}
                  />
                </section>
              </div>
              <div className="top-action-buttons responsive-box">
                {showAddRowButton ? (
                  <>
                    <div className="button-group">
                      {showAddInvoiceButton ? (
                        <CustomButton
                          title={t("createInvoice")}
                          onClick={handleShowInputFields}
                          icon={addIconLight}
                          iconSize={20}
                        />
                      ) : null}
                      {data.length !== 0 && !showInputFields ? (
                        <CustomButton
                          title={t("print")}
                          onClick={handlePrint}
                          variant="gray"
                          icon={printIcon}
                          iconSize={18}
                        />
                      ) : null}
                    </div>
                    <section className="col title-col ">
                      <label>{t("invoicesStatus")}: </label>
                      <Dropdown
                        options={[
                          { title: "Active", value: "active" },
                          { title: "Archived", value: "archived" },
                        ]}
                        selected={selectedInvoicesStatus}
                        setSelected={handleChangeStatus}
                        thick
                      />
                    </section>
                  </>
                ) : null}
              </div>
              {/* {pageLoading?.isPending ? (
                <Loader />
              ) : ( */}
              <>
                {error === "noInvoiceToPrint" ? (
                  <div className="error-message">{t("noInvoiceToPrint")}</div>
                ) : null}
                {data.length === 0 && !showInputFields ? (
                  <InformationBox>
                    {!selectedSupplier?.id ? t("noSupplier") : t("noInvoices")}
                  </InformationBox>
                ) : searchError ? (
                  <>
                    <InformationBox>{t(searchErrorMessage)}</InformationBox>
                  </>
                ) : (
                  <section className="table-container">
                    <table className="i-table">
                      <thead className="table-head">
                        <tr>
                          <th className="bold-text">
                            {/* Select or deselect all */}
                            <CustomCheckbox
                              checked={selectAll}
                              onChange={handleSelectAll}
                              color="primary"
                            />
                          </th>

                          {rows.map((row, index) => {
                            if (row.title === "amountIn") {
                              return (
                                <th
                                  key={index}
                                  className={`bold-text cell-title ${
                                    row.value
                                  } ${
                                    row.value !== "invoiceNumber"
                                      ? "mobile-hider"
                                      : ""
                                  }`}
                                >
                                  {t(row.title) +
                                    " " +
                                    selectedSupplier?.currency}
                                </th>
                              );
                            } else {
                              return (
                                <th
                                  key={index}
                                  className={`bold-text cell-title ${
                                    row.value
                                  } ${
                                    row.value !== "invoiceNumber"
                                      ? "mobile-hider"
                                      : ""
                                  }`}
                                >
                                  {t(row.title)}
                                </th>
                              );
                            }
                          })}
                        </tr>
                      </thead>

                      <tbody className="table-body">
                        {/* These are the existing rows */}
                        {data?.map(
                          (row, index) =>
                            !row.canceled && (
                              <tr key={index}>
                                <td
                                  className={`cell ${
                                    currentDirection === "ltr"
                                      ? "rounded-first-cell"
                                      : "rounded-last-cell"
                                  }`}
                                >
                                  <CustomCheckbox
                                    checked={row.selected || false}
                                    onChange={(e) => {
                                      const updatedRow = [...data];
                                      updatedRow[index].selected =
                                        e.target.checked;
                                      setData(updatedRow);
                                    }}
                                  />
                                </td>

                                <td className="cell invoice-number">
                                  {row.archived ? (
                                    <span>
                                      <p className="archived-invoice">
                                        {getCleanedInvoiceNumber(
                                          row?.invoiceNumber
                                        )}
                                      </p>
                                      {row?.updateCounter > 0 &&
                                        !row?.archived && (
                                          <p className="update-note">
                                            {t("invoiceHasBeenUpdated")}
                                          </p>
                                        )}
                                    </span>
                                  ) : (
                                    <CustomLink to={`/orgInvoice/${row?.id}`}>
                                      <p className="link">
                                        {getCleanedInvoiceNumber(
                                          row?.invoiceNumber
                                        )}
                                      </p>
                                      {row?.updateCounter > 0 &&
                                        !row?.archived && (
                                          <p className="update-note">
                                            {t("invoiceHasBeenUpdated")}
                                          </p>
                                        )}
                                    </CustomLink>
                                  )}
                                </td>

                                <td className="cell mobile-hider">
                                  {toMoney(row.amount)}
                                </td>

                                <td className="cell mobile-hider">
                                  {row.amountCurrency}
                                </td>

                                <td className="cell mobile-hider">
                                  {row.bankName}
                                </td>

                                <td className="cell mobile-hider">
                                  {toMoney(row.amountInSupCur)}
                                </td>

                                <td className="cell mobile-hider">
                                  {row.date
                                    ? new Date(row.date)
                                        ?.toISOString()
                                        .split("T")[0]
                                    : ""}
                                </td>
                                <td
                                  className={`cell notes-cell mobile-hider ${
                                    currentDirection === "ltr"
                                      ? "rounded-last-cell"
                                      : "rounded-first-cell"
                                  }`}
                                >
                                  {row.notes}
                                </td>
                              </tr>
                            )
                        )}
                      </tbody>
                    </table>
                  </section>
                )}

                <section className="pagination">
                  <div className="pagination-buttons">
                    <CustomButton
                      variant="blank"
                      title={t("previous")}
                      onClick={() =>
                        setInvoicesCurrentPage(invoicesCurrentPage - 1)
                      }
                      disabled={invoicesCurrentPage === 1}
                      icon={
                        currentDirection === "ltr" ? previousIcon : nextIcon
                      }
                      iconSize={15}
                    />
                    <span className="pagination-location">
                      {t("page")}: {invoicesCurrentPage} - {invoicesTotalPages}
                    </span>
                    <CustomButton
                      title={t("next")}
                      variant="blank"
                      onClick={() =>
                        setInvoicesCurrentPage(invoicesCurrentPage + 1)
                      }
                      disabled={invoicesCurrentPage === invoicesTotalPages}
                      icon={
                        currentDirection === "ltr" ? nextIcon : previousIcon
                      }
                      iconSize={15}
                      reverseIcon
                    />
                  </div>

                  <div className="pagination-buttons">
                    <span className="pagination-location">
                      {t("totalRecords")}: {invoicesTotalRecords}
                    </span>
                  </div>
                </section>

                {selectedSupplier?.id !== "All" && (
                  <p className="current-balance">
                    <span>{t("currentBalance")}: </span>
                    <span className={balanceStyle}>
                      {toMoney(currentBalance?.value)}{" "}
                      {selectedSupplier?.currency}
                    </span>
                  </p>
                )}
              </>
            </>
          ) : null}

          {loading ? (
            <Loader />
          ) : (
            <>
              {!selectedSupplier?.id && (
                <div className="full-w-button-container">
                  <div className="column-flex-box flex-center-center">
                    <InformationBox>
                      {t("noSuppliersAddFromUser")}
                    </InformationBox>

                    <CustomButton
                      title={t("addSupplier")}
                      onClick={handleShowSupplierModal}
                      icon={supplierIcon}
                      minWidth={140}
                      variant="blank"
                    />
                  </div>
                </div>
              )}
            </>
          )}

          {/* {!suppliers.length && (
            <div className="full-w-button-container">
              {userData.userType === "orgUser" ? (
                <CustomButton
                  title={t("addSupplier")}
                  onClick={handleShowSupplierModal}
                  icon={supplierIcon}
                  minWidth={140}
                  variant="blank"
                />
              ) : (
                <InformationBox>{t("noSuppliersAddFromUser")}</InformationBox>
              )}
            </div>
          )} */}
          {supplierModal && (
            <Modal
              title="addSupplier"
              onClose={() => handleCancel("supplier")}
              footer={
                <>
                  {loadingModal ? <Loader /> : null}
                  <section className={classes.actions}>
                    <CustomButton
                      onClick={handleAddSupplier}
                      title={t("confirm")}
                      icon={saveIcon}
                      iconSize={16}
                      disabled={loadingModal}
                    />
                    <CustomButton
                      title={t("cancel")}
                      onClick={() => handleCancel("supplier")}
                      variant="blank"
                    />
                  </section>
                </>
              }
            >
              <div className={classes.flexableForm}>
                <section className={classes.section}>
                  <div className={classes.inputContainer}>
                    <span
                      className={
                        currentDirection === "rtl" ? classes.shortSpan : ""
                      }
                    >
                      {t("supplierName")}:{" "}
                    </span>
                    <CustomInput
                      name="supplierName"
                      value={supplierFields?.supplierName}
                      onChange={(e) => handleInputChange(e, "supplier")}
                      flex={true}
                      errorText={
                        error === "noSupplierName" ? t("noSupplierName") : null
                      }
                    />
                  </div>
                </section>
                <section className={classes.section}>
                  <div className={classes.inputContainer}>
                    <span
                      className={
                        currentDirection === "rtl" ? classes.shortSpan : ""
                      }
                    >
                      {t("supplierCompany")}:{" "}
                    </span>
                    <CustomInput
                      name="supplierCompany"
                      value={supplierFields?.supplierCompany}
                      onChange={(e) => handleInputChange(e, "supplier")}
                      flex={true}
                    />
                  </div>
                </section>
                <section className={classes.section}>
                  <div className={classes.inputContainer}>
                    <span
                      className={
                        currentDirection === "rtl" ? classes.shortSpan : ""
                      }
                    >
                      {t("supplierLocation")}:{" "}
                    </span>
                    <CustomInput
                      name="supplierLocation"
                      value={supplierFields?.supplierLocation}
                      onChange={(e) => handleInputChange(e, "supplier")}
                      flex={true}
                    />
                  </div>
                </section>
                <section className={classes.section}>
                  <div className={classes.inputContainer}>
                    <span
                      className={
                        currentDirection === "rtl" ? classes.shortSpan : ""
                      }
                    >
                      {t("supplierCurrency")}:{" "}
                    </span>
                    <Dropdown
                      options={currencies}
                      selected={supplierCurrency}
                      setSelected={setSupplierCurrency}
                      thick
                      flex
                    />
                  </div>
                </section>
              </div>
            </Modal>
          )}
          {/* Render the ChartModal component */}
          <ChartModal
            open={chartModalOpen}
            onClose={handleCloseChart}
            data={data}
          />
        </div>
      </main>

      {showInputFields && (
        <Modal
          title="createInvoice"
          onClose={handleCancel}
          footer={
            <>
              {loadingModal ? <Loader /> : null}
              <section className={classes.actions}>
                <CustomButton
                  onClick={handleAddRow}
                  title={t("confirm")}
                  icon={saveIcon}
                  iconSize={16}
                  disabled={loadingModal}
                />
                <CustomButton
                  title={t("cancel")}
                  onClick={handleCancel}
                  variant="blank"
                  icon={closeIcon}
                  iconSize={14}
                />
              </section>
            </>
          }
        >
          <div className={classes.flexableForm}>
            <section>
              <div className={classes.inputContainer}>
                <span>{t("supplier")}: </span>
                <Dropdown
                  options2={suppliers}
                  selected={selectedSupplier}
                  setSelected={handleSelectSupplier}
                  thick
                  flex
                  errorText={error}
                />
              </div>
            </section>
            <section className={`${classes.section} ${classes.invoiceNumber}`}>
              <div className={classes.inputContainer}>
                <span>{t("Invoice Number")}: </span>
                <div className={classes.invoiceNumberContainer}>
                  {dublicatedInvoiceNumber && (
                    <div className="error-message">
                      {t("dublicatedInvoiceNumber")}
                    </div>
                  )}
                  <CustomInput
                    name="invoiceNumber"
                    value={getCleanedInvoiceNumber(
                      newRecord?.invoiceNumber || ""
                    )}
                    onChange={handleInputChange}
                    flex={true}
                    errorText={error}
                  />
                </div>
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("Date")}: </span>
                <CustomInput
                  name="date"
                  type="date"
                  flex={true}
                  value={
                    newRecord?.date
                      ? newRecord.date.toISOString().split("T")[0]
                      : ""
                  }
                  onChange={(date) => handleDateChange(date)}
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("Amount")}: </span>
                <CustomInput
                  name="amount"
                  value={newRecord?.amount || ""}
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyDown(e)}
                  type="money"
                  flex={true}
                  errorText={error}
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("Currency")}: </span>
                <Dropdown
                  options={currencies}
                  selected={selectedCurrency}
                  setSelected={setSelectedCurrency}
                  thick
                  flex
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("Bank name")}: </span>
                <CustomInput
                  name="bankName"
                  value={newRecord?.bankName || ""}
                  onChange={handleInputChange}
                  flex={true}
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("amountIn") + selectedSupplier?.currency}: </span>
                <CustomInput
                  name="amountInSupCur"
                  value={newRecord?.amountInSupCur || ""}
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyDown(e)}
                  type="money"
                  flex={true}
                  errorText={error}
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("Currency rate")}: </span>
                <CustomInput
                  name="currencyRate"
                  value={newRecord?.currencyRate || ""}
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyDown(e)}
                  type="money"
                  flex={true}
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span>{t("SWIFT")}: </span>
                <CustomInput
                  name="swift"
                  value={newRecord?.swift || ""}
                  onChange={handleInputChange}
                  flex={true}
                />
              </div>
            </section>
            <section className={classes.section}>
              <div className={classes.inputContainer}>
                <span className={`${classes.notesSpan}`}>{t("Notes")}: </span>
                <CustomInput
                  name="notes"
                  type="textarea"
                  value={newRecord?.notes || ""}
                  onChange={handleInputChange}
                  flex={true}
                />
              </div>
            </section>
            <PDFUploader
              ref={pdfUploaderRef}
              invoiceNumber={data?.invoiceNumber}
              invoiceDocs={invoiceDocs}
              setInvoiceDocs={setInvoiceDocs}
              mode="upload"
              documents={data?.documents}
              tempDocs={invoiceDocs}
              setTempDocs={setInvoiceDocs}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default InvoicesManagement;
