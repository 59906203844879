import { useContext, useEffect, useRef, useState } from "react";

import classes from "./ViewInvoice.module.css";
import { FormControl, MenuItem, Select } from "@material-ui/core";

import { sanitizeNumber } from "../../utils/sanitizeNumber";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import axios from "axios";

// * Custom utils
import { toMoney } from "../../utils/toMoney";
import { getCleanedInvoiceNumber } from "../../utils/getCleanedInvoiceNumber";

import CustomInput from "../../components/CustomInput";

import printIcon from "../../assets/printer_icon.png";
import archiveIcon from "../../assets/archive_icon.png";
import editIcon from "../../assets/edit_icon.png";
import saveIcon from "../../assets/save_icon.png";
import closeIcon from "../../assets/close_icon.png";
import previousIcon from "../../assets/previous_icon.png";
import nextIcon from "../../assets/next_icon.png";

// * Date Picker
import Header from "../../components/Header";
import { DataContext } from "../../utils/DataContext";
import PDFUploader from "../../components/PDFUploader";
import CustomButton from "../../components/CustomButton";
import useLanguage from "../../utils/useLanguage";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";

export default function ViewInvoice() {
  const navigate = useNavigate();
  const { t } = useTranslation("general");
  const { id } = useParams();
  const { currentDirection } = useLanguage();
  const {
    selectedSupplier,
    fetchInvoices,
    pageLoading,
    setPageLoading,
    currentBalance,
    setCurrentBalance,
  } = useContext(DataContext);

  const pdfUploaderRef = useRef(null);

  const [data, setData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [error, setError] = useState("");
  const [tempCurrentBalance, setTempCurrentBalance] = useState({
    value: 0,
    containerId: 0,
  });
  const [newRecord, setNewRecord] = useState({ date: new Date() });
  const [invoiceDocs, setInvoiceDocs] = useState([]);
  const [tempDocs, setTempDocs] = useState([]);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setPageLoading((prev) => ({
        ...prev,
        isPending: true,
      }));
      const token = localStorage.getItem("token");
      // Ensure userId is stored as a number
      const userId = parseInt(localStorage.getItem("userId"), 10);

      const supplierId = selectedSupplier?.id;

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/getInvoice/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
            "Supplier-Id": supplierId,
          },
        }
      );

      if (response?.status === 200) {
        setPageLoading((prev) => ({
          ...prev,
          isPending: false,
          isSuccess: true,
        }));
      } else {
        setPageLoading((prev) => ({
          ...prev,
          isPending: false,
          isFailed: true,
        }));
      }

      setData(response?.data);
      setInvoiceDocs(response?.data?.documents);
      setNewRecord({
        ...response?.data,
        invoiceNumber: response?.data?.invoiceNumber,
      });
    } catch (error) {
      setPageLoading((prev) => ({
        ...prev,
        isPending: false,
        isFailed: true,
      }));
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = () => {
    setEditMode(true);
    // setNewRecord(data[index]);
  };

  const cancelEdit = () => {
    setEditMode(false);
    setError("");
    setNewRecord(data);
    setTempDocs([]);
  };

  // Prevent entering numbers in input field
  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
      "Enter",
    ];
    const isNumberOrSymbol = /\d|\,|\./.test(e.key);

    if (!isNumberOrSymbol && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleChildUploadDocs = (invoiceId) => {
    if (pdfUploaderRef.current) {
      pdfUploaderRef.current.handleUploadDocs(invoiceId);
    }
  };

  // console.log("invoice docs", invoiceDocs);

  const handleConfirmEdit = async () => {
    // Retrieve and parse the current balance from the context or default to 0
    const currentBalanceValue = parseFloat(currentBalance?.value) || 0;

    // // Retrieve and parse the old amount from the invoice data (before any edits)
    const sanitizedAmountInSupCur = sanitizeNumber(data?.amountInSupCur) || 0;
    const amountInSupCur = parseFloat(sanitizedAmountInSupCur) || 0;

    // // Sanitize and parse the new amount input by the user
    const sanitizedNewAmountInSupCur =
      sanitizeNumber(newRecord.amountInSupCur) || 0;
    const newAmountInSupCur = parseFloat(sanitizedNewAmountInSupCur) || 0;

    // Calculate the new balance after the update (subtract old amount, add new amount)
    const calcCurrentBalance =
      +currentBalanceValue - +amountInSupCur + +newAmountInSupCur;

    // Retrieve the authentication token and user ID from local storage
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);

    // Check if the invoice data has changed by comparing key fields
    const isInvoiceNumberEqual =
      data?.invoiceNumber === newRecord.invoiceNumber;
    const isAmountEqual =
      parseFloat(data?.amount) === parseFloat(newRecord.amount);
    const isDateEqual = data?.date === newRecord.date;
    const isCurrencyRateEqual =
      parseFloat(data?.currencyRate) === parseFloat(newRecord.currencyRate);
    const isAmountInSupCurEqual =
      parseFloat(data?.amountInSupCur) === parseFloat(newRecord.amountInSupCur);
    // Check if there are no new documents added during the edit
    const noNewDocs = !tempDocs.length;

    // EDIT THE INVOCIE (NO NUMBERS) ONLY INFORMATION
    // If no fields have changed and no new documents are uploaded, update the invoice directly
    if (
      isInvoiceNumberEqual &&
      isAmountEqual &&
      // isCurrencyRateEqual &&
      isAmountInSupCurEqual &&
      isDateEqual
    ) {
      console.log("I am in edit");
      try {
        setLoadingModal(true);
        // Send a PUT request to the backend to update the invoice with the existing data
        const editedInvoice = await axios.put(
          `${process.env.REACT_APP_URL}/editInvoice/${data?.id}`,
          { ...newRecord }, // Send the updated record data
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "User-Id": userId,
              "Supplier-Id": data?.supplierId,
            },
          }
        );

        // If the response is invalid, stop the execution
        if (!editedInvoice) {
          return;
        }

        // Log the edited invoice data returned by the backend
        console.log(editedInvoice?.data);

        // Update the invoice data with the returned changes from the backend
        setData((prev) => {
          console.log(prev);
          return { ...prev, ...editedInvoice?.data };
        });

        // Update the new record with the backend's returned data
        setNewRecord((prevRow) => {
          const { received } = newRecord;
          const amount = parseFloat(newRecord.amount) || 0;
          const receivedValue = parseFloat(received) || 0;
          const leftValue = (amount - receivedValue).toFixed(2).toString();

          return { ...editedInvoice.data, received, left: leftValue };
        });
        setLoadingModal(false);

        handleChildUploadDocs(editedInvoice?.data?.id);
      } catch (error) {
        // Log and display any errors that occur during the update process
        console.error("Error updating invoice:", error);
        setError("Error updating invoice.");
      }
    }

    // UPDATE THE INVOCIE (NUMBERS)
    else {
      console.log("I am in update!");

      // If there are changes, send a POST request to update the invoice with new data

      try {
        setLoadingModal(true);
        const updateInvoice = await axios.post(
          `${process.env.REACT_APP_URL}/updateInvoice`,
          {
            ...newRecord, // Send the updated data from the form
            oldAmount: amountInSupCur, // Include the old amount for auditing
            updateCounter: data.updateCounter + 1, // Increment the update counter for tracking changes
            createdAt: data.createdAt, // Include the creation date to maintain consistency
            balanceAfterUpdate: calcCurrentBalance, // Send the updated balance after the amount change
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "User-Id": userId,
              "Supplier-Id": data?.supplierId,
            },
          }
        );

        // If no update occurred, log an error
        if (!updateInvoice) {
          console.error(`updatedCurrentBalance.error`, updateInvoice.error);
        }

        // Update the record and data with the new invoice information returned from the backend
        setNewRecord(updateInvoice?.data?.updatedInvoice);
        setData(updateInvoice?.data?.updatedInvoice);

        // Update the balance context with the new balance returned from the backend
        setCurrentBalance((prev) => ({
          ...prev,
          value: +updateInvoice?.data?.updatedBalance?.value,
        }));

        // Trigger the document upload for the updated invoice
        const uploadDocuments = handleChildUploadDocs(
          updateInvoice?.data?.updatedInvoice?.id
        );
        setLoadingModal(false);
      } catch (error) {
        // Log and display any errors that occur during the update process
        console.error("Error updating invoice:", error);
        setError("Error updating invoice.");
      } finally {
        // Fetch the updated invoices list after the edit completes
        fetchInvoices(selectedSupplier?.id);
        setLoadingModal(false);
      }
    }

    // Exit the edit mode after confirming the changes
    setEditMode(false);
  };

  const handleCloseArchiveModal = () => setShowArchiveModal(false);

  const handleArchive = async () => {
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);

    console.log("Invoice Data", data);

    try {
      const archivedInvoice = await axios.delete(
        `${process.env.REACT_APP_URL}/archiveInvoice/${data?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
            "Supplier-Id": data?.supplierId,
          },
        }
      );

      if (!archivedInvoice?.data) {
        console.error(`archivedInvoice.error`, archivedInvoice.error);
      } else {
        // console.log("archivedInvoice?.data", archivedInvoice?.data);
        setCurrentBalance((prev) => ({
          ...prev,
          value: archivedInvoice?.data?.newBalance?.value,
        }));
      }

      navigate(`/invoiceManagement`);
    } catch (error) {
      console.error("Error updating invoice:", error);
      setError("Error updating invoice.");
    } finally {
      fetchInvoices(selectedSupplier?.id);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setError("");
    setNewRecord((prevRow) => {
      let updatedRow = { ...prevRow, [name]: value };

      if (name === "amountInSupCur") {
        if (value) {
          setTempCurrentBalance((prev) => ({
            ...prev,
            value: currentBalance.value + parseFloat(sanitizeNumber(value)),
          }));
        } else {
          setTempCurrentBalance((prev) => ({
            ...prev,
            value: parseFloat(sanitizeNumber(currentBalance.value)),
          }));
        }
      }
      return updatedRow;
    });
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    setNewRecord((prevRecord) => ({ ...prevRecord, date: new Date(date) }));
  };

  if (pageLoading?.isPending) {
    return <Loader fullHeight />;
  }

  return (
    <>
      <main className={classes.viewInvoicePage}>
        <div className="e-container">
          <div className="user-logout">
            <CustomButton
              title={t("back")}
              variant="blank"
              icon={currentDirection === "ltr" ? previousIcon : nextIcon}
              iconSize={14}
              onClick={() => navigate("/invoiceManagement")}
            />

            <div className={classes.user} style={{ marginTop: "25px" }}>
              <p className={classes.userTitle}>{`${t("user")}: `}</p>
              <p className={classes.userName}>
                {` ${localStorage.getItem("username")}`}
              </p>
            </div>
          </div>

          {data?.archived && (
            <div className="error-message">{t("archivedInvoice")}</div>
          )}

          <section className={classes.section}>
            <h3 className={classes.invoiceDetailsTitle}>
              {t("invoiceDetails")}
            </h3>
          </section>
          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>{t("supplier")}: </span>
              <span className={`${classes.boldText} ${classes.breakWord}`}>
                {data?.Supplier?.name}
              </span>
            </div>
          </section>

          <section className={`${classes.section} ${classes.invoiceNumber}`}>
            <div className={classes.col}>
              <span className={classes.colTitle}>{t("Invoice Number")}: </span>
              {editMode ? (
                <>
                  <CustomInput
                    name="invoiceNumber"
                    value={getCleanedInvoiceNumber(newRecord?.invoiceNumber)}
                    onChange={() => null}
                    disabled
                    readOnly
                  />
                </>
              ) : (
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {getCleanedInvoiceNumber(data?.invoiceNumber)}
                </span>
              )}
            </div>
          </section>

          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>{t("Date")}: </span>
              {editMode ? (
                <CustomInput
                  type="date"
                  name="date"
                  value={
                    newRecord?.date
                      ? new Date(newRecord.date).toISOString().split("T")[0]
                      : "Invalid Date"
                  }
                  onChange={(date) => handleDateChange(date)}
                />
              ) : (
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {data?.date
                    ? new Date(data.date).toISOString().split("T")[0]
                    : "Invalid Date"}
                </span>
              )}
            </div>
          </section>

          <section className={classes.sectionSpecial}>
            <div className={classes.sectionSpecialCol}>
              <span className={classes.colTitle}>{t("Amount")}: </span>
              {editMode ? (
                <CustomInput
                  name="amount"
                  value={newRecord?.amount}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={handleInputChange}
                  type="money"
                />
              ) : (
                <span
                  className={`${classes.sectionSpecialColText} ${classes.breakWord}`}
                >
                  {toMoney(data?.amount)}
                </span>
              )}
            </div>

            <div className={classes.sectionSpecialCol}>
              <span>{t("Currency")}: </span>
              {editMode ? (
                <FormControl>
                  <Select
                    name="amountCurrency"
                    value={newRecord?.amountCurrency}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="EURO">EURO</MenuItem>
                    <MenuItem value="RMB">RMB</MenuItem>
                    <MenuItem value="GBP">GBP</MenuItem>
                    <MenuItem value="GBP">IQD</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <span
                  className={`${classes.sectionSpecialColText} ${classes.breakWord}`}
                >
                  {data?.amountCurrency}
                </span>
              )}
            </div>
          </section>

          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>
                {t("amountIn") + data?.Supplier?.currency}:{" "}
              </span>
              {editMode ? (
                <CustomInput
                  name="amountInSupCur"
                  value={newRecord?.amountInSupCur}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={handleInputChange}
                  type="money"
                />
              ) : (
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toMoney(data?.amountInSupCur)} {selectedSupplier?.currency}
                </span>
              )}
            </div>

            {editMode ? null : (
              <div className={classes.col}>
                <span className={classes.colTitle}>
                  {t("balanceBeforeUpdate")}:{" "}
                </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toMoney(data?.balanceBeforeUpdate)}{" "}
                  {selectedSupplier?.currency}
                </span>
              </div>
            )}
          </section>

          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>{t("Bank name")}: </span>
              {editMode ? (
                <CustomInput
                  name="bankName"
                  value={newRecord?.bankName}
                  onChange={handleInputChange}
                />
              ) : (
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {data?.bankName}
                </span>
              )}
            </div>

            {editMode ? null : (
              <div className={classes.col}>
                <span className={classes.colTitle}>
                  {t("balanceAfterUpdate")}:{" "}
                </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toMoney(data?.balanceAfterUpdate)}{" "}
                  {selectedSupplier?.currency}
                </span>
              </div>
            )}
          </section>

          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>{t("SWIFT")}: </span>
              {editMode ? (
                <CustomInput
                  name="swift"
                  value={newRecord?.swift}
                  onChange={handleInputChange}
                />
              ) : (
                <>
                  {!data?.swift ? (
                    <span className="not-found">{t("noSwift")}</span>
                  ) : null}
                  <span className={`${classes.boldText} ${classes.breakWord}`}>
                    {data?.swift}
                  </span>
                </>
              )}
            </div>
          </section>

          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>{t("Notes")}: </span>
              {editMode ? (
                <CustomInput
                  name="notes"
                  value={newRecord?.notes}
                  onChange={handleInputChange}
                />
              ) : (
                <span className={`${classes.breakWord}`}>{data?.notes}</span>
              )}
            </div>
          </section>

          <PDFUploader
            ref={pdfUploaderRef}
            invoiceNumber={data?.invoiceNumber}
            invoiceDocs={invoiceDocs}
            setInvoiceDocs={setInvoiceDocs}
            mode={!editMode && "show"}
            documents={invoiceDocs}
            tempDocs={tempDocs}
            setTempDocs={setTempDocs}
          />

          {loadingModal ? <Loader /> : null}
          <section className={classes.actions}>
            {editMode ? (
              <>
                <CustomButton
                  title={t("cancel")}
                  onClick={cancelEdit}
                  variant="blank"
                  icon={closeIcon}
                  iconSize={14}
                />
                <CustomButton
                  title={t("confirm")}
                  onClick={handleConfirmEdit}
                  icon={saveIcon}
                  iconSize={16}
                  disabled={loadingModal}
                />
              </>
            ) : (
              <>
                <CustomButton
                  variant="blank"
                  title={t("edit")}
                  onClick={handleEdit}
                  icon={editIcon}
                  iconSize={18}
                />
                {setEditMode ? (
                  <>
                    <CustomButton
                      title={t("print")}
                      onClick={handlePrint}
                      variant="gray"
                      icon={printIcon}
                      iconSize={18}
                    />
                    {!data?.archived && (
                      <CustomButton
                        title={t("archive")}
                        variant="red"
                        icon={archiveIcon}
                        iconSize={20}
                        onClick={() => setShowArchiveModal(true)}
                      />
                    )}
                  </>
                ) : null}
              </>
            )}
          </section>
        </div>
      </main>
      {showArchiveModal && (
        <Modal
          title={t("archiveInvoice")}
          onClose={handleCloseArchiveModal}
          footer={
            <>
              {loadingModal ? <Loader /> : null}
              <section className={classes.actions}>
                <CustomButton
                  onClick={handleArchive}
                  title={t("confirm")}
                  icon={saveIcon}
                  iconSize={16}
                  disabled={loadingModal}
                />
                <CustomButton
                  title={t("cancel")}
                  onClick={handleCloseArchiveModal}
                  variant="blank"
                  icon={closeIcon}
                  iconSize={14}
                />
              </section>
            </>
          }
        >
          <div className={classes.archiveModalBody}>
            <h3 className={classes.archiveModalBodyTitle}>{t("areYouSure")}</h3>
            <p className={classes.archiveModalInvoiceNumber}>
              {data?.invoiceNumber}
            </p>
          </div>
        </Modal>
      )}
    </>
  );
}
