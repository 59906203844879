import LanguageSwitcher from "./LanguageSwitcher";
import logo from "../assets/logo.png";
import { useTranslation } from "react-i18next";
import CustomButton from "./CustomButton";
import { useNavigate } from "react-router-dom";
import logoutIcon from "../assets/logout_icon.png";
import userIcon from "../assets/user.png";

export default function Header({ setIsAuthenticated }) {
  const userType = localStorage.getItem("userType");
  const { t } = useTranslation("general");
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("adminToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("supplierId");
    localStorage.removeItem("customerId");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("SuperAdminToken");
    localStorage.removeItem("organizationId");
    localStorage.removeItem("userType");

    setIsAuthenticated(null);

    if (userType === "admin") {
      navigate("/admin-login", { replace: true });
    } else {
      navigate("/login", { replace: true });
    }
  };

  return (
    <div className="invoice-banner">
      <div className="header-container">
        <img src={logo} alt="Ehsibly" className="logo" />
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <LanguageSwitcher variant="dark" />
          <div className="user-actions">
            {userType !== "admin" && (
              <img
                src={userIcon}
                alt="Profile"
                onClick={() => navigate("/profile")}
              />
            )}
            <CustomButton
              title={t("logout")}
              variant="blank"
              onClick={handleLogout}
              icon={logoutIcon}
              iconSize={20}
            />
          </div>
        </section>
      </div>
    </div>
  );
}
