import { useState, useEffect, useContext } from "react";
import { Container, Typography } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import useLanguage from "../../utils/useLanguage";
import nextIcon from "../../assets/next_icon.png";
import previousIcon from "../../assets/previous_icon.png";
import editIcon from "../../assets/edit_icon.png";
import closeIcon from "../../assets/close_icon.png";
import saveIcon from "../../assets/save_icon.png";
import addIcon from "../../assets/add_icon.png";
import { UserAuth } from "../../utils/AuthContext";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import ErrorBox from "../../components/ErrorBox";
import { DataContext } from "../../utils/DataContext";
import BackToDashboard from "../../components/BackToDashboard";

export default function CustomersPage() {
  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  // const [networkError, setNetworkError] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerModal, setCustomerModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [customerFields, setCustomerFields] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Added pagination state
  const [totalPages, setTotalPages] = useState(1); // Added totalPages state
  const [totalRecords, setTotalRecords] = useState(0); // Added totalRecords state
  const [loadingModal, setLoadingModal] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [newCustomerFields, setNewCustomerFields] = useState({
    name: "",
    phoneNumber: null,
    email: null,
    address: null,
  });

  const { userData } = useContext(UserAuth);
  const { pageLoading } = useContext(DataContext);

  const organizationId = localStorage.getItem("organizationId");
  useEffect(() => {
    const getAllCustomers = async () => {
      const req = await getCustomers();

      setTotalPages(req?.data?.totalPages);
      setTotalRecords(req?.data?.totalCustomers);
    };

    getAllCustomers();
  }, [currentPage, searchTerm, userData]);

  const getCustomers = async (page, limit) => {
    const token =
      localStorage.getItem("SuperAdminToken") ||
      localStorage.getItem("adminToken");
    try {
      const customers = await axios.get(
        `${process.env.REACT_APP_URL}/getCustomers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": organizationId,
          },
          params: {
            page: page ?? 1,
            limit: limit ?? 5,
            search: searchTerm,
          },
        }
      );

      if (customers.data && customers.data.customers) {
        setCustomers(customers.data.customers);
        setFilteredCustomers(customers.data.customers);
      } else {
        console.error("Failed to get the users");
      }
      if (!customers) {
        console.error("Failed to get the cusotmers", error);
      }

      const existingCustomers = customers?.data?.customers;

      setCustomers(existingCustomers);

      console.log("ff 1 ", customers.data.customers);
      console.log("ff 2 ", existingCustomers);

      if (existingCustomers.length) {
        setSelectedCustomer(existingCustomers[0]);
      }
    } catch (error) {
      // setNetworkError(true);
      console.error("Failed to get the Customers", error);
    }
  };

  useEffect(() => {
    setFilteredCustomers(
      customers.filter((customer) =>
        customer.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, customers]);

  // const openCustomerModal = (customer) => {
  //   setSelectedCustomer(customer);
  //   setCustomerFields({
  //     name: customer.name,
  //   }); // Initialize fields for editing

  //   setIsEditing(false); // Set to editing mode
  //   setCustomerModal(true);
  // };

  const openCustomerModal = (customer) => {
    setSelectedCustomer(customer);
    setCustomerFields({
      name: customer.name,
      email: customer.email,
      phoneNumber: customer.phoneNumber,
      address: customer.address,
    });
    setIsEditing(false);
    setCustomerModal(true);
  };

  const handleInputChange = (e, fieldName) => {
    setCustomerFields((prev) => ({
      ...prev,
      [fieldName]: e.target.value,
    }));
  };

  const handleSaveCustomer = async () => {
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);
    try {
      setLoadingModal(true);
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/updateCustomer/${selectedCustomer.id}`,
        {
          customerName: customerFields.name,
          phoneNumber: customerFields.phoneNumber,
          email: customerFields.email,
          address: customerFields.address,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
          },
        }
      );

      if (response.data.message === "Customer updated successfully") {
        await getCustomers();
        setCustomerModal(false);
        console.log("Customer updated successfully");
      } else {
        console.error("Failed to update Customer:", response.data.message);
      }
      setLoadingModal(false);
    } catch (error) {
      console.error("Error updating Customer:", error);
      setLoadingModal(false);
    }
  };

  const handleCancel = () => {
    setCustomerModal(false);
    setSelectedCustomer(null);
  };

  const openAddCustomerModal = () => {
    setAddCustomerModal(true);
    setNewCustomerFields({
      name: "",
    });
  };

  const handleNewCustomerInputChange = (e, fieldName) => {
    const value = fieldName === "isBlocked" ? e.target.checked : e.target.value;
    setNewCustomerFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  const handleCreateCustomer = async (e) => {
    e.preventDefault();

    try {
      // Determine the language based on currentDirection
      const language = currentDirection === "rtl" ? "ar" : "en";

      console.log("newCustomerFields", newCustomerFields);

      //  Empty fields validation
      if (!newCustomerFields.name) {
        setError(true);
        setErrorMessage(t("emptyInputFields"));
        return;
      }

      // // Email validation regex
      // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // if (newUserFields.userEmail) {
      //   if (!emailPattern.test(newUserFields.userEmail)) {
      //     setError(true);
      //     setErrorMessage(t("invalidEmail"));
      //     return;
      //   }
      // }

      setLoadingModal(true);
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/createCustomer`,
        {
          email: newCustomerFields.email,
          customerName: newCustomerFields.name,
          phoneNumber: newCustomerFields.phoneNumber,
          address: newCustomerFields.address,
          // isBlocked: newUserFields.isBlocked,
          // isOrganization: true,
          organizationId: Number(userData?.organizationId),
          userId: Number(userData?.userId),
          // supplierId: selectedSupplier?.id,
          language,
        }
      );

      setMessage(response.data.message);
      setTotalRecords((prev) => prev + 1);
      if (response.data.message === "Customer created successfully") {
        // Close the modal and refresh the user list
        setError(false);
        setErrorMessage("");
        setAddCustomerModal(false);
        await getCustomers();
      }
      setError(false);
      setErrorMessage("");
      setAddCustomerModal(false);
      setLoadingModal(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorKey = error.response.data.error;
        console.log("errorKey:", errorKey);
        // Map backend errors to user-friendly messages
        switch (errorKey) {
          case "emailAlreadyInUse":
            setError(true);
            setErrorMessage(t("emailAlreadyInUse"));
            break;
          case "invalidPhoneNumber":
            setError(true);
            setErrorMessage(t("invalidPhoneNumber"));
            break;
          default:
            setError(true);
            setErrorMessage(t("unexpectedError"));
            break;
        }
      } else {
        console.error("Network or other error:", error.message);
        setError(true);
        // setErrorMessage(t("networkError"));
      }
      setLoadingModal(false);
    }
  };

  const handleCancelAddCustomer = () => {
    setError(false);
    setErrorMessage("");
    setAddCustomerModal(false);
  };

  // if (pageLoading?.isPending) {
  //   return <Loader fullHeight />;
  // }

  return (
    <div className="e-container">
      <div
        className="view-users-page-container"
        style={{ direction: currentDirection }}
      >
        <div>
          <BackToDashboard
            title={t("back")}
            variant="blank-fit-content"
            icon={currentDirection === "ltr" ? previousIcon : nextIcon}
            iconSize={14}
          />
        </div>
        <div className="users-title-section">
          <h4 className="title">
            {t("customers", { defaultValue: "customers" })}
          </h4>

          {userData?.userId ? (
            <CustomButton
              title={t("createCustomer")}
              variant="blank"
              icon={addIcon}
              iconSize={20}
              onClick={openAddCustomerModal}
            />
          ) : null}
        </div>
        <CustomInput
          name="name"
          value={searchTerm}
          placeholder={searchTerm ? "" : t("search")}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {pageLoading?.isPending ? (
          <Loader />
        ) : filteredCustomers.length === 0 ? (
          <p>{t("noCustomersFound")}</p>
        ) : (
          <ul className="users-list" style={{ direction: currentDirection }}>
            {filteredCustomers.map((customer) => (
              <li
                className="user-list-item"
                key={customer.id}
                onClick={() => openCustomerModal(customer)}
                style={{ cursor: "pointer" }}
              >
                <p className="user-username">{customer.name}</p>
                {/* {customer.company && (
                  <Typography variant="subtitle1" color="textSecondary">
                    {customer.company}
                  </Typography>
                )} */}
              </li>
            ))}
          </ul>
        )}
        <section className="pagination">
          <div className="pagination-buttons">
            <CustomButton
              variant="blank"
              title={t("previous")}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              icon={currentDirection === "ltr" ? previousIcon : nextIcon}
              iconSize={15}
            />
            <span className="pagination-location">
              {t("page")}: {currentPage} - {totalPages}
            </span>
            <CustomButton
              title={t("next")}
              variant="blank"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              icon={currentDirection === "ltr" ? nextIcon : previousIcon}
              iconSize={15}
              reverseIcon
            />
          </div>

          <div className="pagination-buttons">
            <span className="pagination-location">
              {t("totalNumber")}: {totalRecords}
            </span>
          </div>
        </section>
        {/* Edit customer Modal */}
        {customerModal && (
          <Modal
            title={isEditing ? t("editCustomer") : t("customer")}
            onClose={handleCancel}
            footer={
              <>
                {loadingModal ? <Loader /> : null}
                <section className="edit-user-actions">
                  {isEditing ? (
                    <>
                      <CustomButton
                        onClick={handleSaveCustomer}
                        title={t("confirm")}
                        icon={saveIcon}
                        iconSize={16}
                        disabled={loadingModal}
                      />
                      <CustomButton
                        title={t("cancel")}
                        onClick={handleCancel}
                        variant="blank"
                        icon={closeIcon}
                        iconSize={14}
                      />
                    </>
                  ) : (
                    <>
                      {userData?.userId ? (
                        <CustomButton
                          variant="blank"
                          title={t("edit")}
                          onClick={() => setIsEditing(true)}
                          icon={editIcon}
                          iconSize={18}
                        />
                      ) : null}
                    </>
                  )}
                </section>
              </>
            }
          >
            {error && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ErrorBox>{errorMessage}</ErrorBox>
              </div>
            )}
            <div>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("customerName")}: </span>
                      <CustomInput
                        name="name"
                        value={customerFields.name || ""}
                        onChange={(e) => handleInputChange(e, "name")}
                        errorText={
                          errorMessage ? t("inputFieldCannotBeEmpty") : ""
                        }
                      />{" "}
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p variant="body1">{t("customerName")}:</p>
                      <p variant="body1">{selectedCustomer?.name}</p>
                    </div>
                  )}
                </div>
              </section>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("email")}: </span>
                      <CustomInput
                        name="email"
                        value={customerFields.email || ""}
                        onChange={(e) => handleInputChange(e, "email")}
                      />{" "}
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p variant="body1">{t("email")}:</p>
                      <p variant="body1">{selectedCustomer?.email}</p>
                    </div>
                  )}
                </div>
              </section>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("phoneNumber")}: </span>
                      <CustomInput
                        name="phoneNumber"
                        value={customerFields.phoneNumber || ""}
                        onChange={(e) => handleInputChange(e, "phoneNumber")}
                      />{" "}
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p variant="body1">{t("phoneNumber")}:</p>
                      <p variant="body1">{selectedCustomer?.phoneNumber}</p>
                    </div>
                  )}
                </div>
              </section>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("address")}: </span>
                      <CustomInput
                        name="address"
                        value={customerFields.address || ""}
                        onChange={(e) => handleInputChange(e, "address")}
                      />{" "}
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p variant="body1">{t("address")}:</p>
                      <p variant="body1">{selectedCustomer?.address}</p>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </Modal>
        )}
        {/* Add Customer Modal */}

        {addCustomerModal && (
          <Modal
            title={t("createCustomer")}
            onClose={handleCancelAddCustomer}
            footer={
              <>
                {loadingModal ? <Loader /> : null}
                <section className="edit-user-actions">
                  <CustomButton
                    title={t("confirm")}
                    onClick={handleCreateCustomer}
                    icon={saveIcon}
                    iconSize={16}
                    disabled={loadingModal}
                  />
                  <CustomButton
                    title={t("cancel")}
                    onClick={handleCancelAddCustomer}
                    variant="blank"
                    icon={closeIcon}
                    iconSize={14}
                  />
                </section>
              </>
            }
          >
            <form onSubmit={handleCreateCustomer}>
              <div>
                {error && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ErrorBox>{errorMessage}</ErrorBox>
                  </div>
                )}
                <div className="edit-user-entity">
                  <span>{t("customerName")}: </span>
                  <CustomInput
                    name="name"
                    value={newCustomerFields.name}
                    onChange={(e) => handleNewCustomerInputChange(e, "name")}
                    errorText={errorMessage ? t("inputFieldCannotBeEmpty") : ""}
                  />
                  <span>{t("email")}: </span>
                  <CustomInput
                    name="email"
                    value={newCustomerFields.email}
                    onChange={(e) => handleNewCustomerInputChange(e, "email")}
                  />
                  <span>{t("phoneNumber")}: </span>
                  <CustomInput
                    name="phoneNumber"
                    value={newCustomerFields.phoneNumber}
                    onChange={(e) =>
                      handleNewCustomerInputChange(e, "phoneNumber")
                    }
                  />
                  <span>{t("address")}: </span>
                  <CustomInput
                    name="address"
                    value={newCustomerFields.address}
                    onChange={(e) => handleNewCustomerInputChange(e, "address")}
                  />
                </div>
              </div>
            </form>
          </Modal>
        )}
      </div>
    </div>
  );
}
